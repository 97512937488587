/*
 * MD3Light or Dark theme is the default theme of react native paper
 * You can specify a primary, secondary, tertiary colors on the react-native-paper documentation and it will
 * give you the colors
 * for this theme, this is a theme with a touch of blue
 * primary - #5585B5
 * secondary - #53A8B6
 * tertiary - #79C2D0
 */
import { MD3LightTheme as DefaultLightTheme } from 'react-native-paper';
//this object will automatically given to you by react-native-paper depends on the tri-color theme you will select on the documentation
// you can modify it to your liking
/*https://callstack.github.io/react-native-paper/docs/guides/theming*/
const blueThemeColors = {
  colors: {
    primary: 'rgb(103, 124, 140)',
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(208, 228, 255)',
    onPrimaryContainer: 'rgb(0, 29, 52)',
    secondary: 'rgb(0, 104, 117)',
    onSecondary: 'rgb(103, 124, 140)',
    secondaryContainer: 'white',
    onSecondaryContainer: 'rgb(0, 31, 36)',
    tertiary: 'rgb(0, 104, 118)',
    onTertiary: 'rgb(255, 255, 255)',
    tertiaryContainer: 'rgb(158, 239, 255)',
    onTertiaryContainer: 'rgb(0, 31, 36)',
    error: 'rgb(186, 26, 26)',
    onError: 'rgb(255, 255, 255)',
    errorContainer: 'rgb(255, 218, 214)',
    onErrorContainer: 'rgb(65, 0, 2)',
    background: 'rgb(252, 252, 255)',
    onBackground: 'rgb(26, 28, 30)',
    surface: 'rgb(252, 252, 255)',
    onSurface: 'rgb(26, 28, 30)',
    surfaceVariant: 'rgb(222, 227, 235)',
    onSurfaceVariant: 'white',
    outline: 'rgb(115, 119, 127)',
    outlineVariant: 'rgb(194, 199, 207)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(47, 48, 51)',
    inverseOnSurface: 'rgb(241, 240, 244)',
    inversePrimary: 'rgb(155, 203, 255)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(239, 244, 250)',
      level2: 'rgb(232, 240, 247)',
      level3: 'rgb(224, 235, 244)',
      level4: 'rgb(222, 234, 244)',
      level5: 'rgb(217, 230, 242)',
    },
    surfaceDisabled: 'rgba(26, 28, 30, 0.12)',
    onSurfaceDisabled: 'rgba(26, 28, 30, 0.38)',
    backdrop: 'rgba(44, 49, 55, 0.4)',
  },
};

export const lightTheme = {
  ...DefaultLightTheme,
  colors: blueThemeColors.colors,
};
