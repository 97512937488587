/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { windowsReload } from '@shared/components/common/helpers/windowsReload';
import { VerificationJob, CameraRenderedResponse } from '@type/vouched.type';

export default function Vouched() {
  const auth = useAuth();
  const navigation = useNavigate();
  const handleVerified = (job: VerificationJob) => {
    if (auth) {
      fetch('https://api.dev.regalcredit.com/core/v1/verified', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorizationToken: `${auth.user?.access_token || ''}`,
        },
        body: JSON.stringify({
          contactId: auth.user?.profile?.contactId,
          vouchedId: job.id,
        }),
      }).catch(error => {
        console.error('Error sending api:', error);
      });
    }
  };
  const loadVouched = () => {
    const existingScript = document.getElementById('vouched');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://static.vouched.id/widget/vouched-2.0.0.js';
      script.id = 'vouched';
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        const vouched = window['Vouched']({ ...config });
        vouched.mount('#vouched-element');
      };
    }
  };

  const config = {
    appId: 'RPO#a9-bP.BPXBR9Y_!61gXOSNk6ee',

    // your webhook for POST verification processing
    callbackURL: 'https://webhooks.dev.regalcredit.com/vouched',

    // optional verification information for comparison please keep for future feature

    // verification: {
    //   firstName: 'Test',
    //   lastName: 'Test',
    //   email: '',
    //   phone: '123123123',
    // },

    liveness: 'orientation',
    id: 'camera',
    includeBarcode: true,
    idLiveness: 'distance',
    showTermsAndPrivacy: true,
    manualCaptureTimeout: 20000,
    maxRetriesBeforeNext: 3,
    numForceRetries: 3,

    // mobile handoff fields, a job will be created automatically if true
    crossDevice: true,
    crossDeviceQRCode: true,
    crossDeviceSMS: true,

    // have the user confirm information
    userConfirmation: {
      confirmData: true,
      confirmImages: true,
    },

    // callback during initialization of the web app
    onInit: ({ token, job }: { token: string; job: VerificationJob }) => {
      handleVerified(job);
    },

    // callback when a user submits a photo
    onSubmit: ({
      stage,
      attempts,
      job,
    }: {
      stage: string;
      attempts: string;
      job: VerificationJob;
    }) => {},

    // called when a job is completed.
    onDone: (job: VerificationJob) => {
      // token used to query jobs
      // token used to query jobs
      if (job.result.success) {
        navigation('/vouched-processing');
        windowsReload();
      } else {
        fetch('https://api.dev.regalcredit.com/core/v1/verified/case', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorizationToken: `${auth.user?.access_token || ''}`,
          },
          body: JSON.stringify({
            caseRecordType: 'Portal Request Process',
            type: 'Verification Failure',
            businessManager: auth.user?.profile?.contactId,
          }),
        })
          .then(response => {
            if (response.ok) {
              navigation('/vouched-processing');
              windowsReload();
            } else {
              throw new Error('Failed to send API request');
            }
          })
          .catch(error => {
            console.error('Error sending API:', error);
          });
      }

      // An alternative way to update your system based on the
      // results of the job. Your backend could perform the following:
      // 1. query jobs with the token
      // 2. store relevant job information such as the id and
      //    success property into the user's profile
      // fetch(`/yourapi/idv?job_token=${job.token}`);

      // Redirect to the next page based on the job success
    },

    // callback executed after attempt to find camera device
    onCamera: ({
      hasCamera,
      hasPermission,
    }: {
      hasCamera: boolean;
      hasPermission: boolean;
    }) => {
      console.log('attempted to find camera', hasCamera, hasPermission);
    },

    // callback when there are changes to the Camera DOM element
    onCameraEvent: (cameraEvent: CameraRenderedResponse) => {},

    // callback when a reverification job is complete
    onReverify: (job: VerificationJob) => {},

    // callback when a survey is submitted, (per customer basis)
    onSurveyDone: (job: VerificationJob) => {},

    // callback when user confirms data and photos
    onConfirm: userConfirmEvent => {
      console.log('user confirmation', userConfirmEvent);
    },

    // theme
    theme: {
      name: 'avant',
      baseColor: '#ED662C',
    },
  };

  useEffect(() => {
    loadVouched();
  }, []);

  return <div id="vouched-element"></div>;
}
