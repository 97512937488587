import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import 'react-loading-skeleton/dist/skeleton.css';

import CallToActionBg from '@assets/images/callToActionBg.png';
import hannahPic from '@assets/images/hannahPic.jpg';
import profileCard from '@assets/images/profileBg.jpg';
import { dashboard } from '@assets/styles/pagesStyle';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';
import SearchBarContainer from '@shared/components/common/SearchBarContainer';
import BarGraph from '@shared/components/graphs/BarGraph';
import LineGraph from '@shared/components/graphs/LineGraph';
import SimpleLineGraph from '@shared/components/graphs/SimpleLineGraph';
import { usePartner } from '@shared/hooks/partnerHook';
import { PartnerContact } from '@type/partnerContact.type';

import CallToAction from '../components/CallToAction';
import Cards from '../components/Cards';
import Kpis from '../components/Kpis';
import Lists from '../components/Lists';
import Profile from '../components/Profile';
import SkeletonDashboard from '../components/SkeletonDashboard';

export default function Dashboard() {
  const navigate = useNavigate();
  const { state, getPartnerContact } = usePartner();

  const [partContactData, setPartContactData] = useState({} as PartnerContact);
  const [_loading, setLoading] = useState(true);
  const [_error, setError] = useState({});

  const [searchQuery, setSearchQuery] = useState('');

  const auth = useAuth();

  const searchFn = (searchQuery: string) => {
    // search functionality here
    navigate('/clients');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPartnerContact(auth.user?.access_token || '');
        if (data) setPartContactData(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  return (
    <PageContainer isClient={false}>
      <Box
        id="content"
        style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
        {state.loading ? (
          <View style={dashboard.centeredContainer}>
            <SkeletonDashboard />
          </View>
        ) : (
          <>
            <View style={styles.welcomeHeader}>
              <Box>
                <WelcomeUserContainer />
                <Text variant="titleLarge">Dashboard</Text>
              </Box>
              <Box>
                <SearchBarContainer
                  searchFn={searchFn}
                  setSearchQuery={setSearchQuery}
                  searchQuery={searchQuery}
                />
              </Box>
            </View>
            <View style={styles.mainView} testID="mainDashboard">
              <Cards size="2col" bannerCard={CallToActionBg}>
                <CallToAction
                  headerText="Welcome to your Credit Report!"
                  subText="Contrary to popular belief, Lorem Ipsum is not simply random text. Contrary to popular belief, Lorem Ipsum is not simply random text. "
                  buttonText="Learn More"
                  link="/clients"
                />
              </Cards>
              <Cards size="1col" icon="fingerprint">
                <Kpis
                  metric="Some alert content"
                  subtext="Contrary to popular belief, Lorem Ipsum is not simply random text. "
                  link="/clients"
                />
              </Cards>
              <Cards
                size="1col"
                profileCard={profileCard}
                profilePic={hannahPic}>
                {partContactData && Object.keys(partContactData).length > 0 && (
                  <Profile
                    profileName={`${partContactData.firstName} ${partContactData.lastName}`}
                    personEmail={partContactData.email}
                    companyEmail={partContactData.email}
                    phoneNumber={partContactData.phone}
                  />
                )}
              </Cards>
            </View>
            <View style={styles.mainView}>
              <Cards title="Total Clients" size="1col" icon="account-multiple">
                <Kpis metric="49" buttonText="View All" link="/clients" />
              </Cards>
              <Cards title="Analysis" size="1col" icon="chart-line">
                <Kpis metric="$137.5K" />
                <SimpleLineGraph heightGraph={120} />
              </Cards>
              <Cards title="Total Unlocks" size="1col" icon="lock-open">
                <Kpis
                  metric="25"
                  subtext="This is some text description about the total number of unlocks"
                />
              </Cards>
              <Cards title="Total Inquiries" size="1col" icon="sticker-text">
                <Kpis metric="12" buttonText="View All" link="/clients" />
              </Cards>
            </View>
            <View style={styles.mainView}>
              <Cards title="Score Tracker" size="1col">
                <Kpis metric="49%" />
                <BarGraph heightGraph={120} />
              </Cards>
              <Cards title="Navigate Items" size="1col">
                <Lists
                  list={[
                    'Text description goes here',
                    'Text description goes here',
                    'Text description goes here',
                    'https://www.google.com/',
                  ]}
                />
              </Cards>
              <Cards title="Debt Analysis" size="2col">
                <Kpis
                  metric="$37.5K"
                  subtext="Total Spent"
                  coloredSubtext=" &uarr;+2.45%"
                />
                <LineGraph heightGraph={120} />
              </Cards>
            </View>
          </>
        )}
      </Box>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    flex: 1,
    margin: 10,
  },
  mainView: {
    display: 'flex',
    flexDirection: 'row',
  },
  welcomeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
});
