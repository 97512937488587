import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Dimensions, View } from 'react-native';
import { Line, LineChart, Tooltip } from 'recharts';

import { SimpleLineGraphProps } from '@type/graph.type';

export default function SimpleLineGraph({
  simpleLineGraphData,
  heightGraph,
}: SimpleLineGraphProps) {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const getComponentSize = () => {
    if (containerRef.current) {
      containerRef.current.measure(
        (
          x: number,
          y: number,
          width: number,
          height: number,
          pageX: number,
          pageY: number,
        ) => {
          setDimensions({ width, height });
        },
      );
    }
  };

  useEffect(() => {
    getComponentSize();
    const listener = Dimensions.addEventListener('change', getComponentSize);

    return () => {
      listener.remove();
    };
  }, []);
  const data = [
    {
      graphData: 1200,
    },
    {
      graphData: 1400,
    },
    {
      graphData: 1000,
    },
    {
      graphData: 1800,
    },
    {
      graphData: 1000,
    },
    {
      graphData: 2100,
    },
    {
      graphData: 1900,
    },
    {
      graphData: 2300,
    },
  ];
  return (
    <View
      style={{ display: 'flex', alignItems: 'center' }}
      testID="simpleLineGraph"
      ref={containerRef}>
      <LineChart
        width={dimensions.width}
        height={heightGraph}
        data={data}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
        <Tooltip />
        <Line
          type="monotone"
          dataKey="graphData"
          stroke="#ED662C"
          dot={false}
          strokeWidth={3}
        />
      </LineChart>
    </View>
  );
}
