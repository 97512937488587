/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { Box } from '@react-native-material/core';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Icon } from 'react-native-paper';

import { CardProps } from '@type/dashboard.type';

import BannerCard from './BannerCard';
import ProfileCard from './ProfileCard';

export default function Cards({
  size,
  icon,
  title,
  children,
  bannerCard,
  profileCard,
  profilePic,
}: CardProps) {
  const isTwoColumns = size === '1col';

  const containerStyle = {
    width: isTwoColumns ? '25%' : '50%',
    padding: 10,
  };

  return (
    <View style={containerStyle}>
      {bannerCard ? (
        <BannerCard icon={icon} title={title} bannerCard={bannerCard}>
          {children}
        </BannerCard>
      ) : profileCard ? (
        <ProfileCard profileCard={profileCard} profilePic={profilePic}>
          {children}
        </ProfileCard>
      ) : (
        <Card style={styles.mainCard} mode="elevated" elevation={1}>
          {icon ? (
            <View style={styles.iconView}>
              <Box style={styles.iconBox}>
                <Icon source={icon} size={24} color="#ED662C" />
              </Box>
            </View>
          ) : (
            <></>
          )}
          {title ? (
            <Card.Title title={title} titleStyle={styles.cardTitle} />
          ) : (
            <></>
          )}
          <Card.Content>{children}</Card.Content>
        </Card>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  cardTitle: {
    color: '#2B3674',
    fontSize: 14,
    fontWeight: '600',
    marginTop: 5,
    opacity: 0.6,
  },
  iconBox: {
    backgroundColor: 'rgba(237, 102, 44, 0.10)',
    borderColor: 'rgba(237, 102, 44, 0.10)',
    borderRadius: 17,
    borderWidth: 1,
    padding: 10,
  },
  iconView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 16,
  },
  mainCard: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    padding: 10,
  },
});
