/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';

import { dashboard } from '@assets/styles/pagesStyle';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';
import SearchBarContainer from '@shared/components/common/SearchBarContainer';
import { Client } from '@type/client.type';

import ClientsTable from '../components/ClientsTable';
import { useClient } from '../hooks/clientHook';

const Clients = () => {
  const navigate = useNavigate();
  const { state, getClient } = useClient();
  const [clientData, setClientData] = React.useState({} as Client);
  const [_loading, setLoading] = React.useState(true);
  const [_error, setError] = React.useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getClient();
        if (data) setClientData(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  const searchFn = (searchQuery: string) => {
    console.log('searchQuery from Dashboard: ', searchQuery);
    navigate('/');
  };
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <PageContainer isClient={false}>
      <Box
        id="content"
        style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
        <View style={styles.welcomeHeader}>
          <Box>
            <WelcomeUserContainer />
            <Text variant="titleLarge">Clients</Text>
          </Box>
          <Box>
            <SearchBarContainer
              searchFn={searchFn}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
            />
          </Box>
        </View>
        <ClientsTable />
      </Box>
    </PageContainer>
  );
};

export default Clients;

const styles = StyleSheet.create({
  boxMarginFlex: {
    flex: 1,
    margin: 10,
  },
  welcomeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
});
