import { useContext } from 'react';

import { PartnerContactContext } from '../contexts/PartnerContext';

export const usePartner = () => {
  const context = useContext(PartnerContactContext);
  if (!context) {
    throw new Error('usePartner must be used within a PartnerProvider');
  }
  return context;
};
