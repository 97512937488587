import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Dimensions, View } from 'react-native';
import { Bar, BarChart, XAxis } from 'recharts';

import { BarGraphProps } from '@type/graph.type';

export default function BarGraph({ barGraphData, heightGraph }: BarGraphProps) {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getComponentSize = () => {
    if (containerRef.current) {
      containerRef.current.measure(
        (
          x: number,
          y: number,
          width: number,
          height: number,
          pageX: number,
          pageY: number,
        ) => {
          setDimensions({ width, height });
        },
      );
    }
  };

  useEffect(() => {
    getComponentSize();
    const listener = Dimensions.addEventListener('change', getComponentSize);

    return () => {
      listener.remove();
    };
  }, []);

  const data = [
    {
      uv: 30,
    },
    {
      uv: 20,
    },
    {
      uv: 38,
    },
    {
      uv: 51,
    },
    {
      uv: 20,
    },
    {
      uv: 30,
    },
    {
      uv: 20,
    },
  ];
  return (
    <View
      style={{ display: 'flex', alignItems: 'center' }}
      testID="barGraph"
      ref={containerRef}>
      <BarChart data={data} width={dimensions.width} height={heightGraph}>
        <XAxis dataKey="uv" />
        <Bar barSize={18} dataKey="uv" fill="#ED662C" />
      </BarChart>
    </View>
  );
}
