/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { Box } from '@react-native-material/core';
import * as React from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { Card, Icon } from 'react-native-paper';

import { BannerCardProps } from '@type/dashboard.type';

export default function BannerCard({
  icon,
  title,
  children,
  bannerCard,
}: BannerCardProps) {
  return (
    <ImageBackground
      source={bannerCard}
      imageStyle={{ borderRadius: 11 }}
      style={{
        height: '100%',
      }}>
      <ImageBackground
        style={{
          background:
            'linear-gradient(96.53deg, rgba(100, 30, 0, 0.62) 7.42%, rgba(114, 34, 0, 0) 96.74%)',
          borderRadius: 11,
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
        }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          {icon ? (
            <View style={styles.iconView}>
              <Box style={styles.iconBox}>
                <Icon source={icon} size={24} color="#ED662C" />
              </Box>
            </View>
          ) : (
            <></>
          )}
          {title ? (
            <Card.Title title={title} titleStyle={styles.cardTitle} />
          ) : (
            <></>
          )}
          <Card.Content>{children}</Card.Content>
        </View>
      </ImageBackground>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  cardTitle: {
    color: '#2B3674',
    fontSize: 14,
    fontWeight: '600',
    marginTop: 5,
    opacity: 0.6,
  },
  iconBox: {
    backgroundColor: 'rgba(237, 102, 44, 0.10)',
    borderColor: 'rgba(237, 102, 44, 0.10)',
    borderRadius: 17,
    borderWidth: 1,
    padding: 10,
  },
  iconView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 16,
  },
});
