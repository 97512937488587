/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-raw-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { dashboard } from '@assets/styles/pagesStyle';
import Forms from '@shared/components/common/forms/Forms';
import { windowsReload } from '@shared/components/common/helpers/windowsReload';
import { Contact } from '@type/vouched.type';

import { useVouched } from '../vouchedHook';

export default function VouchedVerification() {
  const auth = useAuth();
  const [_error, setError] = useState({});
  const { contactState, getCase } = useVouched();
  const { verifiedState, getVerified } = useVouched();
  const [items, setItems] = useState({} as Contact);
  const [caseItem, setCaseItem] = useState<Contact | null>(null);
  const navigation = useNavigate();
  const contactId = auth.user?.profile?.contactId as string;

  useEffect(() => {
    async function fetchData() {
      try {
        const dataCase = await getCase(
          auth.user?.access_token || '',
          contactId,
        );
        const dataVerified = await getVerified(
          auth.user?.access_token || '',
          contactId,
        );
        if (dataCase) {
          setItems(dataCase);
          // Sort the cases array by caseNumber in descending order
          const sortedCases = dataCase.cases.sort(
            (a, b) => parseInt(b.caseNumber) - parseInt(a.caseNumber),
          );
          // Get the first element (case with the highest caseNumber)
          const highestCase = sortedCases[0];
          setCaseItem({
            contactId: dataCase.contactId,
            cases: [highestCase],
          });
        } else {
          if (dataVerified) {
            if (dataVerified.verified == true) {
              navigation('/dashboard');
            } else {
              navigation('/vouched');
              windowsReload();
            }
          } else {
            navigation('/vouched');
            windowsReload();
          }
        }
      } catch (error) {
        setError(error as Error);
      }
    }
    fetchData();
  }, []);

  const handleLogout = () => {
    auth.signoutSilent();
  };

  return (
    <>
      {contactState.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <>
            {(caseItem as Contact)?.cases[0].type === 'Verification Failure' ? (
              <>
                <Box
                  id="content"
                  style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
                  <Forms
                    title={`Case Number  ${(caseItem as Contact)?.cases[0]
                      .caseNumber}`}
                    withEdit={false}>
                    <Text>
                      We apologize for the inconvenience you may be having. We
                      have a support case being managed{' '}
                      {(caseItem as Contact)?.cases[0].caseNumber} for your
                      reference. One of our customer support reps will be in
                      touched to resolve the verification issue.
                    </Text>
                    <View style={{ flexDirection: 'row', marginTop: 10 }}>
                      <Button
                        onPress={handleLogout}
                        mode="contained"
                        style={{
                          backgroundColor: 'transparent',
                          backgroundImage:
                            'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
                        }}>
                        Back to Login
                      </Button>
                    </View>
                  </Forms>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    alignItems: 'center',
    flex: 1,
    margin: 10,
  },
});
