import React from 'react';
import { Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

const WelcomeUserContainer: React.FC = () => {
  const { user } = useAuth();
  const userName = user?.profile?.given_name || '';

  const userHour = new Date().getHours();

  const greetings =
    userHour >= 0 && userHour <= 12
      ? 'Good Morning'
      : userHour > 12 && userHour <= 18
        ? 'Good Afternoon'
        : 'Good Evening';

  return (
    <>
      <Text variant="bodySmall" testID="text-greetings">
        {greetings}, {userName}!
      </Text>
    </>
  );
};

export default WelcomeUserContainer;
