import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Dimensions, View } from 'react-native';
import { Line, LineChart } from 'recharts';

import { LineGraphProps } from '@type/graph.type';

export default function LineGraph({
  lineGraphData,
  heightGraph,
}: LineGraphProps) {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const getComponentSize = () => {
    if (containerRef.current) {
      containerRef.current.measure(
        (
          x: number,
          y: number,
          width: number,
          height: number,
          pageX: number,
          pageY: number,
        ) => {
          setDimensions({ width, height });
        },
      );
    }
  };

  useEffect(() => {
    getComponentSize();
    const listener = Dimensions.addEventListener('change', getComponentSize);

    return () => {
      listener.remove();
    };
  }, []);
  const data = [
    {
      uv: 1200,
      pv: 600,
    },
    {
      uv: 1400,
      pv: 700,
    },
    {
      uv: 1000,
      pv: 500,
    },
    {
      uv: 1800,
      pv: 800,
    },
    {
      uv: 1000,
      pv: 500,
    },
    {
      uv: 2100,
      pv: 1050,
    },
    {
      uv: 1900,
      pv: 950,
    },
    {
      uv: 2300,
      pv: 1150,
    },
  ];
  return (
    <View
      style={{ display: 'flex', alignItems: 'center' }}
      testID="lineGraph"
      ref={containerRef}>
      <LineChart
        width={dimensions.width}
        height={heightGraph}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <Line
          type="monotone"
          dataKey="uv"
          stroke="#ED662C"
          activeDot={{ r: 8 }}
          dot={false}
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#2C4758"
          dot={false}
          strokeWidth={3}
        />
      </LineChart>
    </View>
  );
}
