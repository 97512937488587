/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import { RegistrationLink } from '@type/registrationLink.type';

import { useRegisterLink } from '../registrationLinkHook';

import RegistrationButton from './RegistrationButton';

export default function RegistrationList() {
  const auth = useAuth();
  const [_error, setError] = useState({});
  const { state, getRegisterLink } = useRegisterLink();
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getRegisterLink(auth.user?.access_token || '');
        if (data) {
          setItems(fixLinkData(data));
        }
      } catch (error) {
        setError(error as Error);
      }
    }
    fetchData();
  }, []);

  function fixLinkData(
    data: RegistrationLink,
  ): { name: string; registrationUrl: string }[] {
    const linkData: { name: string; registrationUrl: string }[] =
      data.Products.map(product => ({
        name: product.name,
        registrationUrl: product.registrationUrl,
      }));
    return linkData;
  }

  return (
    <>
      {state.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{ flexDirection: 'row', display: 'flex' }}
            testID="registrationList">
            <RegistrationButton
              title={`${items[0]?.name}`}
              subText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              buttonLink={`${items[0]?.registrationUrl}`}
            />
            <RegistrationButton
              title={`${items[5]?.name}`}
              subText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              buttonLink={`${items[5]?.registrationUrl}`}
            />
            <RegistrationButton
              title={`${items[2]?.name}`}
              subText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              buttonLink={`${items[2]?.registrationUrl}`}
            />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <RegistrationButton
              title={`${items[1]?.name}`}
              subText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              buttonLink={`${items[1]?.registrationUrl}`}
            />
            <RegistrationButton
              title={`${items[3]?.name}`}
              subText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              buttonLink={`${items[3]?.registrationUrl}`}
            />
            <RegistrationButton
              title={`${items[4]?.name}`}
              subText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              buttonLink={`${items[4]?.registrationUrl}`}
            />
          </View>
        </>
      )}
    </>
  );
}
