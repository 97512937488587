import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, RadioButton } from 'react-native-paper';

interface DatePickerProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
}

export default function DatePicker({
  selectedDate,
  onDateChange,
}: DatePickerProps) {
  const [date, setDate] = useState(selectedDate);
  const [selectedOption, setSelectedOption] = useState('Add Reblock Date');

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    onDateChange(newDate);
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    const hrsOption = option === '24hours' ? 24 : 48;
    const newDate = new Date();
    newDate.setHours(newDate.getHours() + hrsOption);
    handleDateChange(newDate);
  };

  return (
    <View style={styles.container}>
      <Text variant="headlineSmall" style={{ padding: 10 }}>
        Add Reblock Date
      </Text>
      <RadioButton.Group
        onValueChange={value => handleOptionChange(value)}
        value={selectedOption}>
        <RadioButton.Item
          value="24hours"
          label="24 hours"
          labelVariant="titleMedium"
          theme={{ colors: { onSurfaceVariant: 'black' } }}
        />
        <RadioButton.Item
          value="48hours"
          label="48 hours"
          labelVariant="titleMedium"
          theme={{ colors: { onSurfaceVariant: 'black' } }}
        />
        <RadioButton.Item
          value="Reblock Date"
          label="Reblock Date"
          labelVariant="titleMedium"
          theme={{ colors: { onSurfaceVariant: 'black' } }}
        />
        {selectedOption === 'Reblock Date' ? (
          <input
            type="date"
            value={date.toISOString().split('T')[0]}
            onChange={e => handleDateChange(new Date(e.target.value))}
            style={{ fontSize: 20, margin: 5 }}
          />
        ) : (
          <></>
        )}
      </RadioButton.Group>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderRadius: 10,
    flex: 1,
    padding: 10,
  },
});
