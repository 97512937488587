import React, { createContext, useReducer } from 'react';

import { partnerService } from '@shared/services/partnerService';
import {
  PartnerContact,
  PartnerContactContextProps,
  PartnerContactProviderProps,
  PartnerContactState,
} from '@type/partnerContact.type';

const initialState: PartnerContactState = {
  partnerContact: {} as PartnerContact,
  loading: false,
  error: null,
};

type Action =
  | { type: 'PROFILE_PARTNER_GET' }
  | { type: 'PROFILE_PARTNER_GET_SUCCESS'; payload: PartnerContact }
  | { type: 'PROFILE_PARTNER_GET_ERROR'; payload: string }
  | { type: 'PROFILE_PARTNER_PATCH_SUCCESS'; payload: Partial<PartnerContact> }
  | { type: 'PROFILE_PARTNER_PATCH_ERROR'; payload: string };

const profilePartnerReducer = (
  state: PartnerContactState,
  action: Action,
): PartnerContactState => {
  switch (action.type) {
    case 'PROFILE_PARTNER_GET':
      return { ...state, loading: true };
    case 'PROFILE_PARTNER_GET_SUCCESS':
      return { ...state, loading: false, partnerContact: action.payload };
    case 'PROFILE_PARTNER_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'PROFILE_PARTNER_PATCH_SUCCESS':
      return {
        ...state,
        partnerContact: { ...state.partnerContact, ...action.payload },
      };
    case 'PROFILE_PARTNER_PATCH_ERROR':
      return { ...state, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const PartnerContactContext = createContext<
  PartnerContactContextProps | undefined
>(undefined);

export const PartnerContactProvider: React.FC<PartnerContactProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(profilePartnerReducer, initialState);

  const getPartnerContact = async (token: string) => {
    dispatch({ type: 'PROFILE_PARTNER_GET' });
    try {
      const partnerContact = await partnerService.getPartnerContact(token);
      dispatch({
        type: 'PROFILE_PARTNER_GET_SUCCESS',
        payload: partnerContact,
      });
      return partnerContact;
    } catch (error) {
      dispatch({
        type: 'PROFILE_PARTNER_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const patchPartnerContact = async (
    token: string,
    partialContact: Partial<PartnerContact>,
  ) => {
    dispatch({
      type: 'PROFILE_PARTNER_PATCH_SUCCESS',
      payload: partialContact,
    });
    try {
      const partnerContact = await partnerService.patchPartnerContact(
        token,
        partialContact,
      );
      dispatch({
        type: 'PROFILE_PARTNER_PATCH_SUCCESS',
        payload: partnerContact,
      });
      return partnerContact;
    } catch (error) {
      dispatch({
        type: 'PROFILE_PARTNER_PATCH_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <PartnerContactContext.Provider
      value={{ state, getPartnerContact, patchPartnerContact }}>
      {children}
    </PartnerContactContext.Provider>
  );
};
