import * as React from 'react';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import usStates from '@assets/jsons/us_states.json';
import ButtonForm from '@shared/components/common/forms/ButtonForm';
import InputField from '@shared/components/common/forms/fields/InputField';
import Forms from '@shared/components/common/forms/Forms';
import { usePartner } from '@shared/hooks/partnerHook';
import { PartnerContact } from '@type/partnerContact.type';

import ChangePassword from './ChangePassword';

export default function PartnerProfile() {
  const { state, getPartnerContact } = usePartner();
  const { patchPartnerContact } = usePartner();

  const [partContactData, setPartContactData] = useState({} as PartnerContact);
  const [_loading, setLoading] = useState(true);
  const [_error, setError] = useState({});
  const [formData, setFormData] = useState({});
  const [isViewMode, setIsViewMode] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [convertedState, setConvertedState] = useState({});
  const [convertedCountry, setConvertedCountry] = useState({});
  const [convertedJson, setConvertedJson] = useState<{
    states: State[];
    countries: Country[];
  }>({ states: [], countries: [] });
  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPartnerContact(auth.user?.access_token || '');
        if (data) {
          setPartContactData(data);
          dataConverter(data);
        }
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    setConvertedJson(jsonToArray(usStates));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  const dataConverter = (data: any) => {
    if (data.mailingCountry) {
      const countryData = {
        value: data.mailingCountry,
        label: data.mailingCountry,
      };
      setConvertedCountry(countryData);
    }
    if (data.mailingState) {
      const stateData = {
        value: data.mailingState,
        label: data.mailingState,
      };
      setConvertedState(stateData);
    }
  };

  interface Country {
    value: string;
    label: string;
    states: { value: string; label: string }[];
  }

  interface State {
    value: string;
    label: string;
  }

  const jsonToArray = (jsonFile: any) => {
    const countries = jsonFile.countries.map((country: Country) => ({
      value: country.value,
      label: country.label,
    }));

    const states = jsonFile.countries.flatMap((country: Country) =>
      country.states.map(state => ({
        value: state.value,
        label: state.label,
      })),
    );
    return { countries, states };
  };

  const handleValueChange = (key: string, value: unknown) => {
    if (key === 'mailingState') {
      const convertedState = convertedJson.states.find(
        state => state.value === value,
      ) || { value: '', label: '' };
      setConvertedState(convertedState);
    }
    if (key === 'mailingCountry') {
      const convertedCountry = convertedJson.countries.find(
        country => country.value === value,
      ) || { value: '', label: '' };
      setConvertedCountry(convertedCountry);
    }
    setFormData({
      ...formData,
      id: partContactData.id,
      [key]: value,
    });
  };

  const saveData = () => {
    setSaveLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isPersonAccount, ...dataWithoutIsPersonAccount } = {
      ...partContactData,
      ...formData,
    };
    patchPartnerContact(
      auth.user?.access_token || '',
      dataWithoutIsPersonAccount,
    ).then(() => {
      window.location.reload();
    });
  };

  const handleToggleEditMode = () => {
    setIsViewMode(!isViewMode);
  };

  const exitEdit = () => {
    window.location.reload();
  };

  const cancelButton = () => {
    setCancelEdit(!cancelEdit);
  };

  const showModal = () => {
    setCancelEdit(!cancelEdit);
  };

  return (
    <>
      {state.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Forms
            title="General Information"
            withEdit={isViewMode}
            handleToggleEditMode={handleToggleEditMode}
            layerIndex={2}>
            <View style={{ flexDirection: 'row', flex: 1, zIndex: 2 }}>
              <InputField
                label="First Name"
                size="25%"
                required={true}
                inputValue={partContactData.firstName}
                onValueChange={value => handleValueChange('firstName', value)}
                isDisabled={isViewMode}
              />
              <InputField
                label="Middle Name"
                size="25%"
                inputValue={partContactData.middleName}
                onValueChange={value => handleValueChange('middleName', value)}
                isDisabled={isViewMode}
              />
              <InputField
                label="Last Name"
                size="25%"
                required={true}
                inputValue={partContactData.lastName}
                onValueChange={value => handleValueChange('lastName', value)}
                isDisabled={isViewMode}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="Email Address"
                size="25%"
                email={true}
                inputValue={partContactData.email}
                onValueChange={value => handleValueChange('email', value)}
                isDisabled={isViewMode}
              />
              <InputField
                label="Phone Number"
                size="25%"
                phoneNumber={true}
                inputValue={partContactData.phone}
                onValueChange={value => handleValueChange('phone', value)}
                isDisabled={isViewMode}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="Address Line"
                inputValue={partContactData.mailingStreet}
                onValueChange={value =>
                  handleValueChange('mailingStreet', value)
                }
                isDisabled={isViewMode}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="City"
                size="25%"
                inputValue={partContactData.mailingCity}
                onValueChange={value => handleValueChange('mailingCity', value)}
                isDisabled={isViewMode}
              />
              <InputField
                label="State"
                size="25%"
                isDisabled={isViewMode}
                dropdownOptions={convertedJson.states}
                inputValue={convertedState}
                onValueChange={state => {
                  handleValueChange('mailingState', state.value);
                }}
              />
              <InputField
                label="Postal Code"
                size="25%"
                numeric={true}
                inputValue={partContactData.mailingPostalCode}
                onValueChange={value =>
                  handleValueChange('mailingPostalCode', value)
                }
                isDisabled={isViewMode}
              />
              <InputField
                label="Country"
                size="25%"
                isDisabled={isViewMode}
                dropdownOptions={convertedJson.countries}
                inputValue={convertedCountry}
                onValueChange={country => {
                  handleValueChange('mailingCountry', country.value);
                }}
              />
            </View>
          </Forms>
          <Forms title={'Security Settings'}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <ChangePassword />
            </View>
          </Forms>
          {isViewMode ? (
            <></>
          ) : (
            <ButtonForm
              saveFn={saveData}
              isLoading={saveLoading}
              cancelFn={cancelButton}
              hideModal={cancelEdit}
              showModal={showModal}
              exitEdit={exitEdit}
            />
          )}
        </>
      )}
    </>
  );
}
