import React, { createContext, useReducer } from 'react';

import {
  Contact,
  VouchContextProps,
  VouchProviderProps,
  VerificationData,
  VouchState,
  VerificationId,
} from '@type/vouched.type';

import { vouchedService } from './vouchedService';

const initialState: VouchState = {
  contactState: { loading: false, error: '', vouchContact: {} as Contact },
  verifiedState: {
    loading: false,
    error: '',
    verifiedData: {} as VerificationData,
  },
  verifiedIdState: {
    loading: false,
    error: '',
    verifiedId: {} as VerificationId,
  },
};
type Action =
  | { type: 'CONTACT_GET' }
  | { type: 'CONTACT_GET_SUCCESS'; payload: Contact }
  | { type: 'CONTACT_GET_ERROR'; payload: string }
  | { type: 'VERIFIED_GET' }
  | { type: 'VERIFIED_GET_SUCCESS'; payload: VerificationData }
  | { type: 'VERIFIED_GET_ERROR'; payload: string }
  | { type: 'VERIFIED_POST' }
  | { type: 'VERIFIED_POST_SUCCESS'; payload: VerificationId }
  | { type: 'VERIFIED_POST_ERROR'; payload: string };

const vouchedReducer = (state: VouchState, action: Action): VouchState => {
  switch (action.type) {
    case 'CONTACT_GET':
      return {
        ...state,
        contactState: { ...state.contactState, loading: true },
      };
    case 'CONTACT_GET_SUCCESS':
      return {
        ...state,
        contactState: {
          ...state.contactState,
          loading: false,
          vouchContact: action.payload,
        },
      };
    case 'CONTACT_GET_ERROR':
      return {
        ...state,
        contactState: {
          ...state.contactState,
          loading: false,
          error: action.payload,
        },
      };
    case 'VERIFIED_GET':
      return {
        ...state,
        verifiedState: { ...state.verifiedState, loading: true },
      };
    case 'VERIFIED_GET_SUCCESS':
      return {
        ...state,
        verifiedState: {
          ...state.verifiedState,
          loading: false,
          verifiedData: action.payload,
        },
      };
    case 'VERIFIED_GET_ERROR':
      return {
        ...state,
        verifiedState: {
          ...state.verifiedState,
          loading: false,
          error: action.payload,
        },
      };
    case 'VERIFIED_POST':
      return {
        ...state,
        verifiedIdState: { ...state.verifiedIdState, loading: true },
      };
    case 'VERIFIED_POST_SUCCESS':
      return {
        ...state,
        verifiedIdState: {
          ...state.verifiedIdState,
          loading: false,
          verifiedId: action.payload,
        },
      };
    case 'VERIFIED_POST_ERROR':
      return {
        ...state,
        verifiedIdState: {
          ...state.verifiedIdState,
          loading: false,
          error: action.payload,
        },
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const VouchedContext = createContext<VouchContextProps | undefined>(
  undefined,
);

export const VouchProvider: React.FC<VouchProviderProps> = ({ children }) => {
  const [contactState, contactDispatch] = useReducer(
    vouchedReducer,
    initialState,
  );
  const [verifiedState, verifiedDispatch] = useReducer(
    vouchedReducer,
    initialState,
  );
  const [verifiedIdState, verifiedIdDispatch] = useReducer(
    vouchedReducer,
    initialState,
  );

  const getCase = async (token: string, contactId: string | '') => {
    contactDispatch({ type: 'CONTACT_GET' });
    try {
      const caseGet = await vouchedService.getCase(token, contactId);
      contactDispatch({ type: 'CONTACT_GET_SUCCESS', payload: caseGet });
      return caseGet;
    } catch (error) {
      contactDispatch({
        type: 'CONTACT_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };
  const getVerified = async (token: string, contactId: string | '') => {
    verifiedDispatch({ type: 'VERIFIED_GET' });
    try {
      const verifiedGet = await vouchedService.getVerified(token, contactId);
      verifiedDispatch({ type: 'VERIFIED_GET_SUCCESS', payload: verifiedGet });
      return verifiedGet;
    } catch (error) {
      verifiedDispatch({
        type: 'VERIFIED_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };
  const postVerified = async (
    token: string,
    contactId: string | '',
    body: VerificationId,
  ) => {
    verifiedIdDispatch({ type: 'VERIFIED_POST' });
    try {
      const verifiedIdState = await vouchedService.postVerified(
        token,
        contactId,
        body,
      );
      verifiedIdDispatch({
        type: 'VERIFIED_POST_SUCCESS',
        payload: verifiedIdState,
      });
      return verifiedIdState;
    } catch (error) {
      verifiedIdDispatch({
        type: 'VERIFIED_POST_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };
  return (
    <VouchedContext.Provider
      value={{
        contactState: contactState.contactState,
        verifiedState: verifiedState.verifiedState,
        verifiedIdState: verifiedIdState.verifiedIdState,
        getCase,
        getVerified,
        postVerified,
      }}>
      {children}
    </VouchedContext.Provider>
  );
};
