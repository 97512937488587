/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';

import { dashboard } from '@assets/styles/pagesStyle';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';

import PartnerProfile from '../components/PartnerProfile';

export default function Settings() {
  return (
    <PageContainer isClient={false}>
      <Box
        testID="settingsPage"
        id="content"
        style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
        <View style={styles.welcomeHeader}>
          <Box>
            <WelcomeUserContainer />
            <Text variant="titleLarge">Settings</Text>
          </Box>
        </View>
        <PartnerProfile />
      </Box>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    flex: 1,
    margin: 10,
  },
  welcomeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
});
