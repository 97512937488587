/* eslint-disable react-native/no-color-literals */
import { Badge } from '@react-native-material/core';
import React from 'react';
import { View } from 'react-native';
import { Searchbar, Avatar, Icon } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

interface SearchBarProps {
  searchFn?: (value: string) => void;
  setSearchQuery: (str: string) => void;
  searchQuery: string;
}

const SearchBarContainer: React.FC<SearchBarProps> = ({
  searchFn,
  setSearchQuery,
  searchQuery,
}) => {
  const { user } = useAuth();
  const userName = user?.profile?.given_name || '';
  const avatarInitial = `${userName.charAt(0)}`;

  return (
    <div className="searchBarContainer" style={styles.container}>
      <Searchbar
        id="searchbar-box"
        data-testid="searchbar-box"
        placeholder="Search"
        onChangeText={setSearchQuery}
        value={searchQuery}
        style={{
          height: 30,
          backgroundColor: '#F8F9FB',
          width: 250,
        }}
        inputStyle={{ minHeight: 0, fontSize: 11, color: '#2B3674' }}
        elevation={1}
        iconColor="#2B3674"
        onIconPress={() => searchFn?.(searchQuery)}
        onSubmitEditing={() => searchFn?.(searchQuery)}
      />
      {/* <Badge
        label={() => <Icon source="bell" size={15} color="#ED662C" />}
        color="#fff"
        data-testid="bell-notif-icon"></Badge> */}
      <View style={styles.viewIcon}>
        <Icon source="bell" color={'#ED662C'} size={20} />
      </View>
      <Avatar.Text
        size={30}
        label={avatarInitial}
        id="profile-loggedin"
        data-testid="profile-loggedin"
        role="status"
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: 25,
    justifyContent: 'space-between',
    boxShadow: '1px 1px 1px 1px rgb(221 221 221)',
  },
  viewIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
};

export default SearchBarContainer;
