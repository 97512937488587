import React, { createContext, useReducer } from 'react';

import {
  PorfileProviderProps,
  ProfileUser,
  ProfileContextProps,
  ProfileState,
} from '@type/profile.type';

import { profileService } from './profileService';

const initialState: ProfileState = {
  profile: {} as ProfileUser,
  loading: true,
  error: null,
};

type Action =
  | { type: 'PROFILE_GET' }
  | { type: 'PROFILE_GET_SUCCESS'; payload: ProfileUser }
  | { type: 'PROFILE_GET_ERROR'; payload: string }
  | { type: 'PROFILE_SET'; payload: ProfileUser };

const profileReducer = (state: ProfileState, action: Action): ProfileState => {
  switch (action.type) {
    case 'PROFILE_GET':
      return { ...state, loading: true };
    case 'PROFILE_GET_SUCCESS':
      return { ...state, loading: false, profile: action.payload };
    case 'PROFILE_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'PROFILE_SET':
      return { ...state, profile: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const ProfileContext = createContext<ProfileContextProps | undefined>(
  undefined,
);

export const ProfileProvider: React.FC<PorfileProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(profileReducer, initialState);

  const getProfile = async (token: string) => {
    dispatch({ type: 'PROFILE_GET' });
    try {
      const profile = await profileService.getProfile(token);
      dispatch({ type: 'PROFILE_GET_SUCCESS', payload: profile });
      return profile;
    } catch (error) {
      dispatch({
        type: 'PROFILE_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <ProfileContext.Provider value={{ state, getProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
