function formatDate(dateString: string | null): string {
  if (dateString === null) {
    return 'N/A';
  }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}-${day}-${year}`;
}

function dateFormatter(date: string | null): string {
  return formatDate(date);
}

export default dateFormatter;
