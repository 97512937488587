import { Surface } from '@react-native-material/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, TextInput, Text, ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import dateFormatter from '@configs/date.config';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { FamilyProfileApiResponse } from '@type/family.type';

function generatePayload(link: string): APIPayload {
  return {
    path: link,
    method: 'GET',
  };
}

export default function FamilyProfiles() {
  const [requestData, setRequestData] =
    useState<FamilyProfileApiResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [DoB, setDoB] = useState(null);
  const [formattedDoB, setFormattedDoB] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const profile = searchParams.get('profile');
  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      const token = auth.user?.access_token;
      const payload = generatePayload(profile?.slice(1) || '');
      const response = await requestAPI(payload, token);
      if (response !== undefined) {
        setRequestData(response.data);
        setDoB(response.data.data.dateOfBirth);
        setLoading(false);
      } else {
        setRequestData(null);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Text style={styles.headContainer} variant="headlineMedium">
        Family Profile
      </Text>
      <Surface elevation={24} style={styles.parentContainer}>
        {loading ? (
          <ActivityIndicator
            animating={true}
            size="large"
            style={{ marginTop: 20 }}
          />
        ) : (
          <>
            <View style={styles.textInputContainer}>
              <TextInput
                label="First Name"
                style={styles.textInput}
                value={requestData?.data?.firstName ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="Last Name"
                style={styles.textInput}
                value={requestData?.data?.lastName ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <View style={styles.emptyCell} />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="Date of Birth"
                style={styles.textInput}
                value={dateFormatter(requestData?.data?.dateOfBirth)}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="SSN"
                style={styles.textInput}
                value={requestData?.data?.SSN ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <View style={styles.emptyCell} />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="Address Line 1"
                style={styles.textInput}
                value={requestData?.data?.address.street ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <View style={styles.emptyCell} />
              <View style={styles.emptyCell} />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="City"
                style={styles.textInput}
                value={requestData?.data?.address.city ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="State"
                style={styles.textInput}
                value={requestData?.data?.address.state ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="Country"
                style={styles.textInput}
                value={requestData?.data?.address.country ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="Family Member Type"
                style={styles.textInput}
                value={requestData?.data?.familyMemberType ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
            </View>
            <View style={styles.emptyCell} />
            <View style={styles.emptyCell} />
            <View style={styles.buttonContainer}>
              <Button
                mode="contained"
                style={[styles.button, { backgroundColor: 'rgb(129 43 43)' }]}>
                Cancel
              </Button>
              <Button
                mode="contained"
                style={[
                  styles.button,
                  { backgroundColor: 'rgb(103, 124, 140)' },
                ]}>
                Save
              </Button>
            </View>
          </>
        )}
      </Surface>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    marginRight: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  emptyCell: {
    flex: 1,
  },
  headContainer: {
    borderRadius: 20,
    marginLeft: 20,
    marginTop: 20,
    padding: 10,
  },
  parentContainer: {
    borderRadius: 20,
    margin: 20,
    padding: 10,
  },
  textInput: {
    backgroundColor: 'white',
    flex: 1,
    marginHorizontal: 5,
  },
  textInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
});
