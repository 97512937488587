/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';

import { dashboard } from '@assets/styles/pagesStyle';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';
import SearchBarContainer from '@shared/components/common/SearchBarContainer';

import RegistrationList from '../components/RegistrationList';

export default function RegistrationLinks() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const searchFn = (searchQuery: string) => {
    console.log('searchQuery from Dashboard: ', searchQuery);
    navigate('/');
  };

  return (
    <PageContainer isClient={false}>
      <Box
        id="content"
        style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
        <View style={styles.welcomeHeader}>
          <Box>
            <WelcomeUserContainer />
            <Text variant="titleLarge">Register a New Client</Text>
          </Box>
          <Box>
            <SearchBarContainer
              searchFn={searchFn}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
            />
          </Box>
        </View>
        <RegistrationList />
      </Box>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    flex: 1,
    margin: 10,
  },
  welcomeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
});
