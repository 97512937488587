/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react-native/no-raw-text */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-native/no-inline-styles */
import { Surface } from '@react-native-material/core';
import React from 'react';
import { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  DataTable,
  Button,
  List,
  Divider,
  Text,
  Avatar,
} from 'react-native-paper';

import DataTablePagination from '@shared/components/common/DataTablePagination';
import { CreditAPIResponse } from '@type/creditBureau.type';

import BureauTableRow from './BureausTableRow';

interface BureausTableProps {
  data: CreditAPIResponse | undefined | null;
}

export default function BureausTable({ data }: BureausTableProps) {
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPageList] = useState([10, 20]);
  const [itemsPerPage, onItemsPerPageChange] = useState(
    numberOfItemsPerPageList[0],
  );

  const bureausList = data?.Bureaus || [];

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, bureausList.length);

  const onClickedAction = (clickedAction: string) => {
    console.log('Clicked Action:', clickedAction);
  };

  useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  return (
    <Surface elevation={24} style={styles.parentContainer}>
      <View style={{ margin: 10, flexDirection: 'row' }} testID="vClient">
        <Avatar.Icon size={30} icon="account" />
        <Text
          testID="textClient"
          variant="headlineSmall"
          style={{ paddingLeft: '10px' }}
          key="textClient">
          Paty Morris
        </Text>
      </View>
      <Divider />
      <View
        style={{
          flexDirection: 'row',
        }}>
        <View style={styles.buttonContainer}>
          <Button
            icon="lock-open"
            mode="contained"
            role="unlockAllBtn"
            theme={{ colors: { primary: 'rgb(103, 124, 140)' } }}
            style={{ margin: 10 }}>
            UNBLOCK ALL
          </Button>
          <Button
            icon="lock"
            mode="contained"
            theme={{ colors: { primary: 'rgb(129, 43, 43)' } }}
            style={{ margin: 10 }}>
            BLOCK ALL
          </Button>
        </View>
        <View style={{ alignItems: 'flex-end', flex: 1 }}>
          <List.Section style={{ display: '-webkit-box' }}>
            <List.Item
              title="Block"
              left={() => <List.Icon icon="lock-check-outline" />}
            />
            <List.Item
              title="Unblock"
              left={() => (
                <List.Icon color="#000" icon="lock-open-remove-outline" />
              )}
            />
            <List.Item
              title="Check Status"
              left={() => <List.Icon color="#000" icon="list-status" />}
            />
          </List.Section>
        </View>
      </View>
      <View style={styles.emptyCell} />
      <DataTable testID="bureausTable" key="bureausTable">
        <DataTable.Header>
          <DataTable.Title
            accessibilityLabel="cellHeader"
            key="titleBureauName">
            Bureau Name
          </DataTable.Title>
          <DataTable.Title
            accessibilityLabel="cellHeader"
            key="titleBureauType">
            Type
          </DataTable.Title>
          <DataTable.Title
            accessibilityLabel="cellHeader"
            key="titleBureauStage">
            Stage
          </DataTable.Title>
          <DataTable.Title
            accessibilityLabel="cellHeader"
            key="titleBureauScore">
            Score
          </DataTable.Title>
          <DataTable.Title
            style={{ justifyContent: 'center' }}
            key="titleBureauActions"
            accessibilityLabel="cellHeader">
            Actions
          </DataTable.Title>
        </DataTable.Header>

        {bureausList &&
          bureausList.length > 0 &&
          bureausList
            .slice(from, to)
            .map((item, index) => (
              <BureauTableRow
                item={item}
                key={index}
                onActionBtn={onClickedAction}
              />
            ))}

        <DataTablePagination
          page={page}
          itemsPerPageList={numberOfItemsPerPageList}
          itemsPerPage={itemsPerPage}
          onPageChange={setPage}
          label={bureausList.length}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          showFastPaginationControls
          selectPageDropdownLabel={'Rows per page'}
        />
      </DataTable>
    </Surface>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  childContainer: {
    textAlign: 'center',
  },
  emptyCell: {
    flex: 1,
  },
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    margin: 20,
    padding: 10,
  },
  testClass: {
    backgroundColor: 'red',
  },
});
