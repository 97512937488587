import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { Family } from '@type/family.type';

export const familyService = {
  async getFamily(token: string): Promise<Family> {
    const payload: APIPayload = {
      method: 'GET',
      path: 'core/v1/client/0015400000xqRF5AAM/family',
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching partner contact.');
    const family = res.data.data as Family;
    return family;
  },
};
