/* eslint-disable react-native/no-color-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

import DataTableComponent from '@shared/components/common/DataTableComponent';
import { Family } from '@type/family.type';

import { useFamily } from '../familyHook';

export default function FamilyTable() {
  const [items, setItems] = useState<any[]>([]);
  const { state, getFamily } = useFamily();
  const [_error, setError] = useState({});

  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getFamily(auth.user?.access_token || '');
        if (data) {
          setItems(renderFamilyData(data));
        }
      } catch (error) {
        setError(error as Error);
      }
    }

    fetchData();
  }, []);

  function renderFamilyData(data: Family) {
    const familyData: {
      key: string;
      fullName: string;
      relationship: string;
      profile: string;
    }[] = [];
    familyData.push({
      key: 'spouse',
      fullName: data.spouse.fullName,
      relationship: 'Spouse',
      profile: data.spouse.profile,
    });
    data?.dependents.forEach((dependent, index) => {
      familyData.push({
        key: `dependetent ${index}`,
        fullName: dependent.fullName,
        relationship: 'Dependent',
        profile: dependent.profile,
      });
    });
    return familyData;
  }

  return (
    <>
      {state.loading ? (
        <ActivityIndicator />
      ) : (
        <DataTableComponent
          pageTitle="Manage Family"
          buttonTitle="Add Member"
          tableData={items}
          tableTitles={[
            {
              key: 'fullName',
              title: 'Full Name',
            },
            {
              key: 'relationship',
              title: 'Relationship',
            },
          ]}
          cellRenderer={(value: any) => {
            return <Text style={styles.subText}>{value}</Text>;
          }}
          actionChildrenTitle="Actions"
          actionChildren={
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                mode="contained"
                style={styles.buttonManage}
                rippleColor="#ED662C">
                <Link
                  to="test"
                  style={{ color: '#ED662C', textDecoration: 'none' }}>
                  Manage
                </Link>
              </Button>
              <Button
                mode="contained"
                style={styles.buttonDeactivate}
                rippleColor="#ED662C">
                <Link
                  to="test"
                  style={{ color: '#ED662C', textDecoration: 'none' }}>
                  Deactivate
                </Link>
              </Button>
            </View>
          }
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  buttonDeactivate: {
    backgroundColor: '#FFFFFF',
    borderColor: '#ED662C',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: 120,
  },
  buttonManage: {
    backgroundColor: '##FF1818',
    borderColor: '#FF1818',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: 120,
  },
  subText: {
    color: '#2B3674',
    cursor: 'auto',
    fontSize: 15,
    fontWeight: '700',
  },
});
