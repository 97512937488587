/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React, { CSSProperties } from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Icon } from 'react-native-paper';

interface PromoCardProps {
  addtlStyle: CSSProperties;
  children: React.ReactNode;
}

export default function PromoCard({ addtlStyle, children }: PromoCardProps) {
  return (
    <>
      <Card
        style={{ ...addtlStyle, ...styles.mainCard }}
        mode="elevated"
        elevation={1}
        testID="promoCard">
        <View style={styles.centerDisplay}>
          <Icon source={'crown-circle'} size={40} color={'#fff'} />
        </View>
        <Card.Content style={styles.content}>{children}</Card.Content>
      </Card>
    </>
  );
}

const styles = StyleSheet.create({
  centerDisplay: {
    display: 'flex',
    borderColor: 'white',
    borderRadius: 150,
    top: -40,
    height: 75,
    marginLeft: '25%',
    position: 'absolute',
    width: 75,
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
    border: '5px solid #FFF',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainCard: {
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
    margin: 10,
    minHeight: 180,
  },
  content: {
    top: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
