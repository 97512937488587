import React, { useLayoutEffect, useState } from 'react';
import { Dimensions } from 'react-native';

export function calculateHeight() {
  const screenHeight = Dimensions.get('window').height;
  return screenHeight - 64;
}

export const getDeviceByScreenSize = (screenWidth: number) => {
  const screenSizes = {
    desktop: screenWidth >= 1200,
    laptop: screenWidth >= 1024,
    tablet: screenWidth >= 768,
    mobile: screenWidth < 768,
  };

  return Object.keys(screenSizes).find(key => screenSizes[key] === true);
};
