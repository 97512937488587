import { requestAPI } from '@shared/utils/apiUtil';
import { CreditAPIResponse } from '@type/creditBureau.type';

export async function getClientCreditBureaus(
  clientID: string,
  token: string,
): Promise<CreditAPIResponse | null> {
  const path = `client/${clientID}/credit`;
  const apiData = await requestAPI({ path: path, method: 'GET' }, token);
  return apiData?.data.data;
}
