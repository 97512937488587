import React, { createContext, useReducer } from 'react';

import {
  Client,
  ClientState,
  ClientContextProps,
  ClientProviderProps,
} from '@type/client.type';

import { clientService } from '../services/clientsService';

const initialState: ClientState = {
  client: { name: '' },
  loading: false,
  error: null,
};

type Action =
  | { type: 'CLIENT_GET' }
  | { type: 'CLIENT_GET_SUCCESS'; payload: Client }
  | { type: 'CLIENT_GET_ERROR'; payload: string }
  | { type: 'CLIENT_UPDATE' }
  | { type: 'CLIENT_UPDATE_SUCCESS'; payload: Client }
  | { type: 'CLIENT_UPDATE_ERROR'; payload: string };

const clientReducer = (state: ClientState, action: Action): ClientState => {
  switch (action.type) {
    case 'CLIENT_GET':
      return { ...state, loading: true };
    case 'CLIENT_GET_SUCCESS':
      return { ...state, loading: false, client: action.payload };
    case 'CLIENT_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const ClientContext = createContext<ClientContextProps | undefined>(
  undefined,
);

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(clientReducer, initialState);

  const getClient = async () => {
    dispatch({ type: 'CLIENT_GET' });
    try {
      const client = await clientService.getClient();
      dispatch({ type: 'CLIENT_GET_SUCCESS', payload: client });
      return client;
    } catch (error) {
      dispatch({
        type: 'CLIENT_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const updateClient = async (updates: Partial<Client>) => {
    dispatch({ type: 'CLIENT_UPDATE' });
    try {
      const updatedClient = await clientService.updateClient(updates);
      dispatch({ type: 'CLIENT_UPDATE_SUCCESS', payload: updatedClient });
      return updatedClient;
    } catch (error) {
      dispatch({
        type: 'CLIENT_UPDATE_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <ClientContext.Provider value={{ state, getClient, updateClient }}>
      {children}
    </ClientContext.Provider>
  );
};
