import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { RegistrationLink } from '@type/registrationLink.type';

export const registrationLinkService = {
  async getRegisterLink(token: string): Promise<RegistrationLink> {
    const payload: APIPayload = {
      method: 'GET',
      path: 'core/v1/partner/0015400000xQGJiAAO/registration',
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching register link.');
    const profile = res.data.data as RegistrationLink;
    return profile;
  },
};