import { useContext } from 'react';

import { VouchedContext } from './VouchedContext';

export const useVouched = () => {
  const context = useContext(VouchedContext);
  if (!context) {
    throw new Error('useVouched must be used within a VouchedProvider');
  }
  return context;
};
