/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { Modal, StyleSheet, View } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';

import { ButtonFormProps } from '@type/form.type';

export default function ButtonForm({
  saveFn = () => {},
  cancelFn = () => {},
  isLoading,
  hideModal,
  showModal = () => {},
  exitEdit = () => {}
}: ButtonFormProps) {
  return (
    <>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Button
            mode="contained"
            style={styles.buttonCancel}
            rippleColor="#ED662C"
            textColor="#ED662C"
            onPress={() => exitEdit()}>
            Cancel
          </Button>
          <Button
            mode="contained"
            style={styles.buttonSave}
            rippleColor="#ED662C"
            textColor="white"
            onPress={() => showModal()}>
            Save Data
          </Button>
        </View>
        <View>
          <Modal visible={hideModal} transparent={true}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                {isLoading ? (
                  <ActivityIndicator />
                ) : (
                  <>
                    <Text variant="titleMedium" style={styles.titleText}>
                      Are you sure?
                    </Text>
                    <View style={{ flexDirection: 'row' }}>
                      <Button
                        mode="contained"
                        style={styles.buttonCancel}
                        rippleColor="#ED662C"
                        textColor="#ED662C"
                        onPress={() => cancelFn()}>
                        No
                      </Button>
                      <Button
                        mode="contained"
                        style={styles.buttonSave}
                        rippleColor="#ED662C"
                        textColor="white"
                        onPress={() => saveFn()}>
                        Yes
                      </Button>
                    </View>
                  </>
                )}
              </View>
            </View>
          </Modal>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  buttonCancel: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FF1818',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: 120,
  },
  buttonSave: {
    backgroundColor: '#ED662C',
    borderColor: '#FF1818',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: 120,
  },
  centeredView: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flex: 1,
    justifyContent: 'center',
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    margin: 20,
    padding: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  titleText: {
    color: '#2B3674',
    fontWeight: '700',
    margin: 5,
  },
});
