/* eslint-disable react-native/no-color-literals */
import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { Box } from '@react-native-material/core';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const termsConditions =
    'https://regalcredit.com/wp-content/uploads/2023/11/creditblock-regalcredit-com-terms-conditions.pdf';
  const privacyPolicy =
    'https://regalcredit.com/wp-content/uploads/2023/11/Regal-Credit-Management-Privacy-Policy.pdf';

  return (
    <View style={styles.footerContainer} testID="FooterParent">
      <Box style={{ display: 'inline', marginLeft: 15 }}>
        <Text style={styles.footerCopyright}>
          {`Copyright ${currentYear} Regal Credit Management`}{' '}
        </Text>
      </Box>
      <Box style={{ display: 'inline', marginRight: 15 }}>
        <a
          href={termsConditions}
          style={styles.footerLinks}
          role="link"
          testID="termsOfService">
          Terms of Service
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href={privacyPolicy}
          style={styles.footerLinks}
          role="link"
          testID="privacyPolicy">
          Privacy Policy
        </a>
      </Box>
    </View>
  );
}

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
  footerCopyright: {
    color: '#8086AC',
    fontSize: 12,
  },
  footerLinks: {
    color: '#2B3674',
    fontSize: 12,
  },
});
