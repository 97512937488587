import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { PartnerContact } from '@type/partnerContact.type';

export const partnerService = {
  async getPartnerContact(token: string): Promise<PartnerContact> {
    const payload: APIPayload = {
      method: 'GET',
      path: 'core/v1/partner/contacts/0035400000vnneIAAQ',
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching partner contact.');
    const partnerContact = res.data.data as PartnerContact;
    return partnerContact;
  },

  async patchPartnerContact(
    token: string,
    formData: Partial<PartnerContact>,
  ): Promise<PartnerContact> {
    const payload: APIPayload = {
      method: 'PATCH',
      path: 'core/v1/partner/contacts/0035400000vnneIAAQ',
      body: formData,
    };
    const res = (await requestAPI(payload, token)) as Record<string, unknown>;
    if (!res || !res.data)
      throw new Error('Error in updating partner contact.');
    const partnerContact = res.data as PartnerContact;
    return partnerContact;
  },
};
