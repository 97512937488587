import * as React from 'react';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import InputField from '@shared/components/common/forms/fields/InputField';
import Forms from '@shared/components/common/forms/Forms';
import { ProfileUser } from '@type/profile.type';

import { useProfile } from '../profileHook';

export default function Profile() {
  const auth = useAuth();
  const [formattedDate, setFormattedDate] = useState<Date>();
  const [_error, setError] = useState({});
  const { state, getProfile } = useProfile();
  const [items, setItems] = useState({} as ProfileUser);


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getProfile(auth.user?.access_token || '');
        if (data) setItems(data);
      } catch (error) {
        setError(error as Error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (items.DoB) {
      const [month, day, year] = items.DoB.split('/').map(Number);
      const date = new Date(year, month - 1, day);
      setFormattedDate(date.toISOString().split('T')[0]);
    }
  }, [items.DoB]);

  return (
    <>
      {state.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Forms title="General Information" withEdit={false}>
            <View style={{ flexDirection: 'row', flex: 1, zIndex: 2 }}>
              <InputField
                label="First Name"
                size="25%"
                required={true}
                inputValue={items.FirstName}
                isDisabled={true}
              />
              <InputField
                label="Last Name"
                size="25%"
                required={true}
                inputValue={items.LastName}
                isDisabled={true}
              />
              <InputField
                label="Phone Number"
                size="25%"
                numeric={true}
                inputValue={items.Mobile}
                isDisabled={true}
              />
              <InputField
                label="Cell Number"
                size="25%"
                numeric={true}
                isDisabled={true}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="SSN"
                size="25%"
                inputValue={items.SSN}
                isDisabled={true}
              />
              <InputField
                label="Account Manager"
                size="25%"
                isDisabled={true}
              />
              <InputField
                label="Email Address"
                size="25%"
                email={true}
                inputValue={items.Email}
                isDisabled={true}
              />
              <InputField
                label="Date of Birth"
                size="25%"
                date={true}
                required={true}
                inputValue={formattedDate}
                isDisabled={true}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="Address Line"
                size="50%"
                inputValue={items.Address?.Street}
                isDisabled={true}
              />
              <InputField
                label="Address Line 2 (Optional)"
                size="50%"
                isDisabled={true}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="City"
                size="25%"
                inputValue={items.Address?.City}
                isDisabled={true}
              />
              <InputField
                label="State"
                size="25%"
                inputValue={items.Address?.State}
                isDisabled={true}
              />
              <InputField
                label="Postal Code"
                size="25%"
                numeric={true}
                inputValue={items.Address?.PostalCode}
                isDisabled={true}
              />
              <InputField
                label="Country"
                size="25%"
                inputValue={items.Address?.Country}
                isDisabled={true}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="Team Email Address"
                size="25%"
                isDisabled={true}
              />
              <InputField label="Team Email CC" size="25%" isDisabled={true} />
            </View>
          </Forms>
          <Forms title="3 Credit Bureaus">
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField label="Username" size="25%" />
              <InputField
                label="Password"
                size="25%"
                password={true}
                isDisabled={true}
              />
              <InputField
                label="Phone Number"
                size="25%"
                numeric={true}
                isDisabled={true}
              />
              <InputField
                label="Email Address"
                size="25%"
                email={true}
                isDisabled={true}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <InputField
                label="What is the name of your favorite pet?"
                size="25%"
              />
              <InputField
                label="PIN"
                size="25%"
                numeric={true}
                isDisabled={true}
              />
            </View>
          </Forms>
        </>
      )}
    </>
  );
}
