import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyleSheet } from 'react-native';

import 'react-loading-skeleton/dist/skeleton.css';

export default function SkeletonDashboard() {
  return (
    <SkeletonTheme borderRadius={10}>
      <div style={stylesSkeleton.skeletonContainer}>
        <section style={stylesSkeleton.section}>
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'43vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
        </section>
        <section style={stylesSkeleton.section}>
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21.25vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21.25vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21.25vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21.25vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
        </section>
        <section style={stylesSkeleton.section}>
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            width={'21vw'}
            height={'30vh'}
            style={stylesSkeleton.boxSkeleton}
          />
          <Skeleton
            enableAnimation={true}
            count={1}
            height={'30vh'}
            width={'43vw'}
            style={stylesSkeleton.boxSkeleton}
          />
        </section>
      </div>
    </SkeletonTheme>
  );
}

const stylesSkeleton = StyleSheet.create({
  boxSkeleton: {
    display: 'flex',
    marginRight: 8,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 16,
    width: '100%',
  },
});
