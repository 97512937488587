import { Surface } from '@react-native-material/core';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { DataTable, Switch, Button } from 'react-native-paper';

import DataTablePagination from '@shared/components/common/DataTablePagination';

interface ClientItem {
  key: number;
  bName: string;
  status: string;
  action: boolean;
}

function ClientTableRow({
  item,
  isSwitchOn,
  onToggleSwitch,
}: {
  item: ClientItem;
  isSwitchOn: boolean;
  onToggleSwitch: () => void;
}) {
  return (
    <DataTable.Row key={item.key}>
      <DataTable.Cell>{item.bName}</DataTable.Cell>
      <DataTable.Cell>{item.status}</DataTable.Cell>
      <DataTable.Cell>
        <Switch value={isSwitchOn} onValueChange={onToggleSwitch} />
      </DataTable.Cell>
    </DataTable.Row>
  );
}

export default function ClientsTable() {
  const [page, setPage] = React.useState<number>(0);
  const [numberOfItemsPerPageList] = React.useState([3, 4]);
  const [itemsPerPage, onItemsPerPageChange] = React.useState(
    numberOfItemsPerPageList[0],
  );

  const [items, setItems] = React.useState([
    {
      key: 1,
      bName: 'TransUnion',
      status: 'Unlocked',
      action: false,
    },
    {
      key: 2,
      bName: 'Equifax',
      status: 'Unlocked',
      action: false,
    },
    {
      key: 3,
      bName: 'Experian',
      status: 'Unlocked',
      action: false,
    },
  ]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, items.length);

  const onToggleSwitch = (key: number) => {
    // Create a new array with updated switch state for the clicked item
    setItems(prevItems =>
      prevItems.map(item =>
        item.key === key ? { ...item, action: !item.action } : item,
      ),
    );
  };

  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  return (
    <Surface elevation={24} style={styles.parentContainer}>
      <View style={styles.buttonContainer}>
        <Button
          mode="contained"
          theme={{ colors: { primary: 'rgb(103, 124, 140)' } }}
          style={{ margin: 10 }}>
          UNLOCK ALL
        </Button>
        <Button
          mode="contained"
          theme={{ colors: { primary: 'rgb(129, 43, 43)' } }}
          style={{ margin: 10 }}>
          LOCK ALL
        </Button>
      </View>
      <View style={styles.emptyCell} />
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Bureau Name</DataTable.Title>
          <DataTable.Title>Status</DataTable.Title>
          <DataTable.Title>Actions</DataTable.Title>
        </DataTable.Header>

        {items.slice(from, to).map(item => (
          <ClientTableRow
            key={item.key}
            item={item}
            isSwitchOn={item.action}
            onToggleSwitch={() => onToggleSwitch(item.key)}
          />
        ))}

        <DataTablePagination
          page={page}
          itemsPerPageList={numberOfItemsPerPageList}
          itemsPerPage={itemsPerPage}
          onPageChange={setPage}
          label={items.length}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          showFastPaginationControls
          selectPageDropdownLabel={'Rows per page'}
        />
      </DataTable>
    </Surface>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  // Unused styles
  // childContainer: {
  //   textAlign: 'center',
  // },
  emptyCell: {
    flex: 1,
  },
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    margin: 20,
    padding: 10,
  },
});
