import React, { useEffect } from 'react';
import { SafeAreaView, Text } from 'react-native';
import { useAuth } from 'react-oidc-context';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

import { config } from '@configs/app.config';
import ForgotPassword from '@features/Authentication/pages/ForgotPassword';
import Login from '@features/Authentication/pages/Login';
import { ClientProvider } from '@features/Clients/context/ClientsContext';
import Clients from '@features/Clients/pages/Clients';
import ManageCredit from '@features/CreditBureaus/pages/ManageCredit';
import { CreditLockProvider } from '@features/CreditLock/CreditLockContext';
import LockMyCredit from '@features/CreditLock/pages/LockMyCredit';
import Dashboard from '@features/Dashboard/pages/Dashboard';
import FamilyProfiles from '@features/Family/pages/FamilyProfiles';
import ManageFamily from '@features/Family/pages/ManageFamily';
import MyProfile from '@features/Profile/pages/MyProfile';
import { ProfileProvider } from '@features/Profile/ProfileContext';
import RegistrationLinks from '@features/RegistrationLinks/pages/RegistrationLinks';
import { RegistrationLinkProvider } from '@features/RegistrationLinks/RegistrationLinkContext';
import ViewReport from '@features/Report/pages/ViewReport';
import Settings from '@features/Settings/pages/Settings';
import Vouched from '@features/Vouched/components/Vouched';
import VouchedProcessing from '@features/Vouched/components/VouchedProcessing';
import VouchedVerification from '@features/Vouched/pages/VouchedVerification';
import { VouchProvider } from '@features/Vouched/VouchedContext';
import Callback from '@shared/components/authWeb/callback';
import Protected from '@shared/components/authWeb/protectedPages';
import VouchedProtected from '@shared/components/authWeb/vouchedPages';
import { windowsReload } from '@shared/components/common/helpers/windowsReload';
import LoadingSpinner from '@shared/components/layout/LoadingSpinner';
import { PartnerContactProvider } from '@shared/contexts/PartnerContext';

import NotFound from './NotFound';

const { issuer, clientId } = config.kc;

export default function App() {
  const auth = useAuth();
  auth && auth.activeNavigator;

  useEffect(() => {
    if (auth && auth.isAuthenticated && !auth.isLoading) {
      auth
        .querySessionStatus()
        .catch((error: { error: string; session_state: string }) => {
          if (error.error === 'login_required' && !error.session_state) {
            window.sessionStorage.removeItem(`oidc.user:${issuer}:${clientId}`);
            windowsReload();
          }
        });
    }
  });

  switch (auth && auth.activeNavigator) {
    case 'signinSilent':
    case 'signoutRedirect':
      return (
        <>
          <Text>Signing you in/out...</Text>
          <LoadingSpinner />
        </>
      );
  }

  const routes = createBrowserRouter([
    {
      path: '/',
      element: (auth && auth.isAuthenticated && !auth.isLoading && (
        <Navigate to="/dashboard" replace />
      )) || <Navigate to="/login" replace />,
    },
    {
      path: '/login',
      element: (auth && auth.isAuthenticated && !auth.isLoading && (
        <Navigate to="/vouched-verification" replace />
      )) || <Login />,
    },
    {
      path: '/vouched-verification',
      element: (
        <Protected>
          <VouchProvider>
            <VouchedVerification />
          </VouchProvider>
        </Protected>
      ),
    },
    {
      path: '/vouched-processing',
      element: (
        <Protected>
          <VouchProvider>
            <VouchedProcessing />
          </VouchProvider>
        </Protected>
      ),
    },
    {
      path: '/vouched',
      element: (
        <Protected>
          <VouchProvider>
            <Vouched />
          </VouchProvider>
        </Protected>
      ),
    },
    {
      path: '/logout',
      element: <Login />,
    },
    {
      path: '/callback',
      element: <Callback />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/dashboard',
      element: (
        <VouchedProtected>
          <PartnerContactProvider>
            <Dashboard />
          </PartnerContactProvider>
        </VouchedProtected>
      ),
    },
    {
      path: '/clients',
      element: (
        <VouchedProtected>
          <ClientProvider>
            <Clients />
          </ClientProvider>
        </VouchedProtected>
      ),
    },
    {
      path: '/lock-my-credit',
      element: (
        <VouchedProtected>
          <CreditLockProvider>
            <LockMyCredit />
          </CreditLockProvider>
        </VouchedProtected>
      ),
    },
    {
      path: '/manage-credit',
      element: (
        <VouchedProtected>
          <ManageCredit />
        </VouchedProtected>
      ),
    },
    {
      path: '/manage-family',
      element: (
        <VouchedProtected>
          <ManageFamily />
        </VouchedProtected>
      ),
    },
    {
      path: '/family-profile',
      element: (
        <VouchedProtected>
          <FamilyProfiles />
        </VouchedProtected>
      ),
    },
    {
      path: '/my-profile',
      element: (
        <VouchedProtected>
          <ProfileProvider>
            <MyProfile />
          </ProfileProvider>
        </VouchedProtected>
      ),
    },
    {
      path: '/view-report',
      element: (
        <VouchedProtected>
          <ViewReport />
        </VouchedProtected>
      ),
    },
    {
      path: '/*',
      element: <NotFound />,
    },
    {
      path: '/settings',
      element: (
        <VouchedProtected>
          <PartnerContactProvider>
            <Settings />
          </PartnerContactProvider>
        </VouchedProtected>
      ),
    },
    {
      path: '/add-new-client',
      element: (
        <VouchedProtected>
          <RegistrationLinkProvider>
            <RegistrationLinks />
          </RegistrationLinkProvider>
        </VouchedProtected>
      ),
    },
  ]);

  return (
    <SafeAreaView>
      {/* Please keep for debugging purposes */}
      {/* <Text>Authenticated: {String(auth.isAuthenticated)}</Text> */}
      <RouterProvider router={routes} />
    </SafeAreaView>
  );
}
