/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-raw-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { dashboard } from '@assets/styles/pagesStyle';
import Forms from '@shared/components/common/forms/Forms';

import { useVouched } from '../vouchedHook';

export default function VouchedProcessing() {
  const auth = useAuth();
  const [_error, setError] = useState({});
  const { verifiedState, getVerified } = useVouched();
  const contactId = auth.user?.profile?.contactId as string;
  const navigation = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const isVerified = await getVerified(
          auth.user?.access_token || '',
          contactId,
        );
        if (isVerified?.verified) {
          navigation('/dashboard');
        } else {
          navigation('/vouched-verification');
        }
      } catch (error) {
        setError(error as Error);
      }
    }
    fetchData();
  }, []);

  const handleLogout = () => {
    auth.signoutSilent();
  };

  return (
    <>
      {verifiedState.loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Box
            id="content"
            style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
            <Forms title={`Verification Complete! `} withEdit={false}>
              <Text>
                We will check and process your verification details and will get
                back to you for your login credentials. Thank you!
              </Text>
              <View style={{ flexDirection: 'row', marginTop: 10 }}>
                <Button
                  onPress={handleLogout}
                  mode="contained"
                  style={{
                    backgroundColor: 'transparent',
                    backgroundImage:
                      'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
                  }}>
                  Back to Login
                </Button>
              </View>
            </Forms>
          </Box>
        </>
      )}
      ;
    </>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    alignItems: 'center',
    flex: 1,
    margin: 10,
  },
});
