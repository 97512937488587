/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import * as React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { Card, Avatar } from 'react-native-paper';

import { ProfileCardProps } from '@type/dashboard.type';

export default function ProfileCard({
  profileCard,
  profilePic,
  children,
}: ProfileCardProps) {
  return (
    <Card
      style={styles.mainCard}
      mode="elevated"
      elevation={1}
      testID="profileCard">
      <ImageBackground
        source={profileCard}
        imageStyle={{ borderRadius: 11 }}
        style={{
          minHeight: 144.5,
          position: 'relative',
        }}>
        <Avatar.Image source={profilePic} style={styles.avatar} />
      </ImageBackground>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
}

const styles = StyleSheet.create({
  avatar: {
    borderColor: 'white',
    borderRadius: 150,
    borderWidth: 4,
    bottom: -20,
    height: 72,
    marginLeft: 20,
    position: 'absolute',
    width: 72,
  },
  mainCard: {
    backgroundColor: '#FFFFFF',
    height: '100%',
  },
});
