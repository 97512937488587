import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

import LoadingSpinner from '../layout/LoadingSpinner';

interface ProtectedProps {
  children: React.ReactNode;
}

const Protected: React.FC<ProtectedProps> = ({ children }) => {
  const auth = useAuth();

  console.log('PROTECTED', auth.isAuthenticated, auth.isLoading);

  if (auth.isAuthenticated && !auth.isLoading) {
    return children;
  } else if (auth.isLoading) {
    <LoadingSpinner />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default Protected;
