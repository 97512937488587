/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
import { KC_CLIENT_ID, KC_REALM, KC_REDIRECT_URL_WEB } from '@env';
import React from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { Link } from 'react-router-dom';

export default function ChangePassword() {
  return (
    <View
      style={{
        display: 'flex',
      }}>
      <Button
        mode="contained"
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
        }}>
        <Link
          style={{ color: 'white', textDecoration: 'none' }}
          to={`https://sso.stg.regalcredit.com/auth/realms/${KC_REALM}/protocol/openid-connect/auth?client_id=${KC_CLIENT_ID}&redirect_uri=${KC_REDIRECT_URL_WEB}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`}>
          Change Password
        </Link>
      </Button>
    </View>
  );
}
