/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { TextInput, Text, HelperText, ToggleButton } from 'react-native-paper';
import Select from 'react-select';

import { InputFieldProps } from '@type/form.type';
export default function InputField({
  label,
  size,
  numeric,
  email,
  password,
  date,
  dropdownOptions,
  multiline,
  multilineHeight,
  multiSelect,
  required,
  inputValue,
  onValueChange,
  isDisabled,
  phoneNumber,
}: InputFieldProps) {
  const [text, setText] = useState(inputValue || '');
  const isTwoColumns = size;
  const [isError, setIsError] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const containerStyle = {
    padding: 5,
    width: '50%',
  };

  switch (isTwoColumns) {
    case '25%':
      containerStyle.width = '25%';
      break;
    case '50%':
      containerStyle.width = '50%';
      break;
    case '75%':
      containerStyle.width = '75%';
      break;
    default:
      containerStyle.width = '100%';
  }

  const onBlurRequired = () => {
    if (required && text.trim() === '') {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  };

  const onChangeTextHandler = (inputText: string) => {
    if (numeric) {
      const numericValue = inputText.replace(/[^0-9]/g, '');
      setText(numericValue);
    } else if (email) {
      setText(inputText);
      if (!isValidEmail(inputText)) {
        setIsError(true);
        setErrorText('Invalid email format');
      } else {
        setIsError(false);
      }
    } else if (password) {
      setText(inputText);
      if (text.length < 8) {
        setIsError(true);
        setErrorText(
          'Please make sure your password is greater than 8 characters',
        );
      } else {
        setIsError(false);
      }
    } else if (date) {
      setText(inputText);
    } else if (phoneNumber) {
      const cleaned = inputText.replace(/\D/g, '');
      let formatted = '';
      if (cleaned.length > 0) {
        formatted = `(+${cleaned.slice(0, 1)}`;
        if (cleaned.length > 1) {
          formatted += `) ${cleaned.slice(1, 4)}`;
          if (cleaned.length > 4) {
            formatted += `-${cleaned.slice(4, 8)}`;
          }
        }
      }
      setText(formatted);
    } else {
      setText(inputText);
    }
    if (onValueChange) {
      onValueChange(inputText);
    }
  };

  useEffect(() => {
    setText(inputValue || '');
  }, [inputValue]);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <View style={containerStyle as StyleProp<ViewStyle>}>
      <Text variant="titleMedium" style={styles.titleText}>
        {label} {required ? '*' : <></>}
      </Text>
      {date ? (
        <input
          onBlur={onBlurRequired}
          value={text}
          onChange={e => onChangeTextHandler(e.target.value)}
          type="date"
          style={{
            fontSize: 20,
            borderRadius: 50,
            padding: 13,
            borderColor: 'transparent',
            background: '#F5F6F9B2',
            color: '#8F9BBA',
            outline: 'none',
          }}
          disabled={isDisabled}
        />
      ) : dropdownOptions ? (
        <Select
          styles={{
            control: base => ({
              ...base,
              backgroundColor: '#F5F6F9B2',
              borderRadius: 50,
              padding: 10,
              borderColor: isDisabled ? '#DDDEE1' : 'transparent',
            }),
            input: base => ({
              ...base,
              color: '#8F9BBA',
            }),
          }}
          value={inputValue}
          options={dropdownOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onBlur={onBlurRequired}
          isDisabled={isDisabled}
          onChange={onChangeTextHandler}
        />
      ) : multiSelect ? (
        <Select
          styles={{
            control: base => ({
              ...base,
              backgroundColor: '#F5F6F9B2',
              borderRadius: 50,
              padding: 10,
              borderColor: 'transparent',
            }),
            input: base => ({
              ...base,
              color: '#8F9BBA',
            }),
          }}
          value={inputValue}
          isMulti
          options={multiSelect}
          className="basic-multi-select"
          classNamePrefix="select"
          onBlur={onBlurRequired}
          isDisabled={isDisabled}
        />
      ) : (
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ flex: 1 }}>
            <TextInput
              placeholder="Type Here"
              value={text}
              onChangeText={onChangeTextHandler}
              onBlur={onBlurRequired}
              keyboardType={
                numeric
                  ? 'numeric'
                  : email
                    ? 'email-address'
                    : password
                      ? 'default'
                      : 'default'
              }
              secureTextEntry={password && !showPassword}
              outlineColor="#F5F6F9B2"
              mode="outlined"
              style={{
                backgroundColor: '#F5F6F9B2',
                color: 'black',
                height: multilineHeight,
              }}
              theme={{ roundness: 50 }}
              placeholderTextColor={'#8F9BBA'}
              activeOutlineColor="black"
              multiline={multiline}
              disabled={isDisabled}
            />
          </View>
          {password && (
            <View style={{ justifyContent: 'center' }}>
              <ToggleButton
                icon={showPassword ? 'eye' : 'eye-off'}
                iconColor="black"
                onPress={togglePasswordVisibility}
              />
            </View>
          )}
        </View>
      )}
      <View style={{ marginTop: 10 }}>
        <HelperText type={'error'} visible={isError}>
          {errorText}
        </HelperText>
        <HelperText type={'error'} visible={isRequired}>
          This field is required
        </HelperText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  titleText: {
    color: '#2B3674',
    fontWeight: '700',
    margin: 5,
  },
});
