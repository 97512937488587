export const creditActions = {
  checkStatus: {
    label: 'Check Status',
    icon: 'list-status',
  },
  block: {
    label: 'Block',
    icon: 'lock-check-outline',
  },
  unblock: {
    label: 'Unblock',
    icon: 'lock-open-remove-outline',
  },
};
