import React, { createContext, useReducer } from 'react';

import {
  RegistrationLink,
  RegistrationLinkState,
  RegistrationLinkContextProps,
  RegistrationLinkProviderProps,
} from '@type/registrationLink.type';

import { registrationLinkService } from './registrationLinkService';

const initialState: RegistrationLinkState = {
  registerLink: {} as RegistrationLink,
  loading: true,
  error: null,
};

type Action =
  | { type: 'REGISTER_GET' }
  | { type: 'REGISTER_GET_SUCCESS'; payload: RegistrationLink }
  | { type: 'REGISTER_GET_ERROR'; payload: string }

const registerLinkReducer = (
  state: RegistrationLinkState,
  action: Action,
): RegistrationLinkState => {
  switch (action.type) {
    case 'REGISTER_GET':
      return { ...state, loading: true };
    case 'REGISTER_GET_SUCCESS':
      return { ...state, loading: false, registerLink: action.payload };
    case 'REGISTER_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const RegistrationLinkContext = createContext<
  RegistrationLinkContextProps | undefined
>(undefined);

export const RegistrationLinkProvider: React.FC<
  RegistrationLinkProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(registerLinkReducer, initialState);

  const getRegisterLink = async (token: string) => {
    dispatch({ type: 'REGISTER_GET' });
    try {
      const registerLink = await registrationLinkService.getRegisterLink(token);
      dispatch({ type: 'REGISTER_GET_SUCCESS', payload: registerLink });
      return registerLink;
    } catch (error) {
      dispatch({
        type: 'REGISTER_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <RegistrationLinkContext.Provider value={{ state, getRegisterLink }}>
      {children}
    </RegistrationLinkContext.Provider>
  );
};
