import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { viewReport } from '@assets/styles/pagesStyle';
import { useFamily } from '@features/Family/familyHook';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';

import CreditScore from '../components/CreditScore';

export default function ViewReport() {
  const [clientName, isClientName] = useState('');
  const { state } = useFamily();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check if selectedFamilyData is loaded
    if (state.selectedFamilyMember) {
      setIsLoading(false);
      isClientName(state.selectedFamilyMember.fullName);
    }
  }, [state.selectedFamilyMember]);

  return (
    <PageContainer isClient={true}>
      <Box style={{ margin: 10, flex: 1, height: calculateHeight() }}>
        <View style={viewReport.centeredContainer}>
          {isLoading ? (
            <View>
              <ActivityIndicator size="large" />
            </View>
          ) : (
            <CreditScore
              score={630}
              radius={100}
              strokeWidth={10}
              whoLogin={clientName}
            />
          )}
        </View>
      </Box>
    </PageContainer>
  );
}
