import { CreditLock, UpdatedCreditLock } from '@type/creditLock.type';

export const creditLockService = {
  async getCreditLock(): Promise<CreditLock> {
    const creditLock = {
      name: 'John Doe',
    };
    return creditLock;
  },
  async updateCreditLock(
    updates: Partial<CreditLock>,
  ): Promise<UpdatedCreditLock> {
    const updatedCreditLock = {
      name: 'John Doe',
      ...updates,
    };
    return updatedCreditLock;
  },
};
