import { StyleSheet } from 'react-native';

export const main = StyleSheet.create({
  centeredContainer: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'center',
  },
  childContainer: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  parentContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: 0,
  },
});

export const dashboard = StyleSheet.create({
  centeredContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

export const viewReport = StyleSheet.create({
  centeredContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'center',
  },
});

export const settings = StyleSheet.create({
  centeredContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 20,
    marginTop: 30,
    textAlign: 'center',
  },
});
