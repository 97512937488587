import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { VerificationData } from '@type/vouched.type';

import LoadingSpinner from '../layout/LoadingSpinner';

interface ProtectedProps {
  children: React.ReactNode;
}

const vouchedService = {
  async getVerified(
    token: string,
    contactId: string,
  ): Promise<VerificationData> {
    const payload: APIPayload = {
      method: 'GET',
      path: `core/v1/verified/businessManager/${contactId}`,
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching case.');
    const profile = res.data.data as VerificationData;
    return profile;
  },
};

const VouchedProtected: React.FC<ProtectedProps> = ({ children }) => {
  const auth = useAuth();
  const contactId = auth.user?.profile?.contactId as string;
  const [isVerified, setIsVerified] = useState<VerificationData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const verifiedData = await vouchedService.getVerified(
          auth.user?.access_token || '',
          contactId,
        );
        setIsVerified(verifiedData);
      } catch (error) {
        console.error('Error fetching verification data:', error);
        setIsVerified(null);
      }
    };
    fetchData();
  }, []);

  console.log('PROTECTED', auth.isAuthenticated, auth.isLoading);
  console.log('isVerified', isVerified);

  if (auth.isAuthenticated && !auth.isLoading) {
    if (isVerified && isVerified.verified) {
      return children;
    } else {
      return <LoadingSpinner />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default VouchedProtected;
