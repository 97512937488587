import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { Contact, VerificationData, VerificationId } from '@type/vouched.type';

export const vouchedService = {
  async getCase(token: string, contactId: string): Promise<Contact> {
    const payload: APIPayload = {
      method: 'GET',
      path: `core/v1/verified/case/${contactId}`,
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching case.');
    const profile = res.data.data as Contact;
    return profile;
  },
  async getVerified(
    token: string,
    contactId: string,
  ): Promise<VerificationData> {
    const payload: APIPayload = {
      method: 'GET',
      path: `core/v1/verified/businessManager/${contactId}`,
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching case.');
    const profile = res.data.data as VerificationData;
    return profile;
  },
  async postVerified(
    token: string,
    contactId: string,
    body: VerificationId,
  ): Promise<VerificationId> {
    const payload: APIPayload = {
      method: 'POST',
      path: `core/v1/verified/${contactId}`,
      body: body,
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in POST verified.');
    const profile = res.data.data as VerificationId;
    return profile;
  },
};
