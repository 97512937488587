import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { Box } from '@react-native-material/core';
import { View, StyleSheet } from 'react-native';
import { Icon } from 'react-native-paper';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';

import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';

import MenuNavigation from '@shared/components/layout/Navigation';
import Footer from '@shared/components/layout/Footer';

import { getDeviceByScreenSize } from '../common/helpers/displayDimensions';

interface PageProps {
  children: any;
  isClient: boolean;
}

// true = open navigation | false = collapsed navigation
const navStatus = {
  desktop: true,
  laptop: true,
  tablet: false,
  mobile: false,
};

export default function PageContainer({ children, isClient }: PageProps) {
  const { state } = useLocation();

  const { width } = useWindowDimensions();
  let device = getDeviceByScreenSize(width);
  const navStateOnLayoutChange = navStatus[device as keyof typeof navStatus];

  const [navOpen, setNavOpen] = useState(navStateOnLayoutChange);
  const navIconColor = navOpen ? '#ED662C' : '#A3AED0';

  const handleNav = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    const device = getDeviceByScreenSize(width);
    const navStateOnLayoutChange = navStatus[device as keyof typeof navStatus];
    setNavOpen(navStateOnLayoutChange);
  }, [width]);

  useEffect(() => {
    const navState = state;
    if (navState !== null) {
      setNavOpen(state);
    }
  }, []); // run onLoad

  return (
    <>
      <View style={styles.component} data-testID="side-navigation">
        <Box id="side-navigation" style={styles.boxSideNav}>
          <MenuNavigation
            isClient={isClient}
            setNavOpen={setNavOpen}
            navOpen={navOpen}
            handleNav={handleNav}
          />
        </Box>
        <Box
          style={{
            position: 'fixed',
            height: '90px',
            zIndex: 1,
            width: 20,
          }}>
          {/* arrow opened navigation  */}
          <IconButton
            sx={{
              ...styles.iconArrowNavOpen,
              '&:hover': { backgroundColor: 'transparent' },
              ...(!navOpen && { display: 'none' }),
              transitionDelay: '1s',
            }}
            onClick={handleNav}>
            <Icon source="chevron-left-circle" color={navIconColor} size={30} />
          </IconButton>
          {/* arrow collapsed navigation  */}
          <IconButton
            sx={{
              ...styles.iconArrowNavCollapsed,
              '&:hover': { backgroundColor: 'transparent' },
              ...(navOpen && { display: 'none' }),
              transitionDelay: '1s',
            }}
            onClick={handleNav}>
            <Icon
              source="chevron-right-circle"
              color={navIconColor}
              size={25}
            />
          </IconButton>
        </Box>
        <Box id="children" style={styles.boxChildren}>
          <View style={[styles.parentContainer]}>{children}</View>
          <View
            style={{
              marginTop: 'auto',
              height: 50,
              marginBottom: '100',
              position: 'sticky',
            }}>
            <Footer />
          </View>
        </Box>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    backgroundColor: '#F5F7F9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: 0,
    textAlign: 'center',
  },
  component: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
  },
  boxSideNav: {
    flexDirection: 'column',
    margin: 0,
    height: calculateHeight(),
  },
  boxPromocontent: {
    flex: 1,
    justifyContent: 'space-between',
    position: 'absolute',
  },
  boxChildren: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    padding: 10,
    backgroundColor: '#F5F7F9',
    minHeight: '100vh',
  },
  iconArrowNavOpen: {
    zIndex: 999,
    // width: '355px', // commented for a purpose, original width of the button for debugging purpose
    marginLeft: '170px',
    paddingTop: '40px',
    // position: 'relative',
  },
  iconArrowNavCollapsed: {
    zIndex: 999,
    // width: '175px', // commented for a purpose, original width of the button for debugging purpose
    marginLeft: '80px',
    paddingTop: '35px',
  },
});
