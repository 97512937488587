/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-raw-text */
import React from 'react';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { Link } from 'react-router-dom';

import DataTableComponent from '@shared/components/common/DataTableComponent';

export default function ClientsTable() {
  const [items] = useState([
    {
      key: 1,
      fName: 'Levi',
      lName: 'Ackerman',
      status: 'Unblock',
      unblockDate: '11/01/23-12/08/23',
      famCount: 12,
      subLink: '1subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 2,
      fName: 'Light',
      lName: 'Yagami',
      status: 'Block',
      unblockDate: '12/11/23-12/12/23',
      famCount: 9,
      subLink: '2subLink',
      action: true,
      id: 'A0015400000xqRF5ASDADAD',
    },
    {
      key: 3,
      fName: 'Tanjiro',
      lName: 'Kamado',
      status: 'Unblock',
      unblockDate: '12/15/23-12/28/23',
      famCount: 4,
      subLink: '3subLink',
      action: true,
      id: 'A0015400000xqRF5AAM',
    },
    {
      key: 4,
      fName: 'Hinata',
      lName: 'Hyuga',
      status: 'Block',
      unblockDate: '12/11/23-12/12/23',
      famCount: 8,
      subLink: '4subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 5,
      fName: 'Naruto',
      lName: 'Uzumaki',
      status: 'Block',
      unblockDate: '12/11/23-12/12/23',
      famCount: 11,
      subLink: '5subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 6,
      fName: 'Nico',
      lName: 'Robin',
      status: 'Unblock',
      unblockDate: '12/11/23-12/12/23',
      famCount: 3,
      subLink: '6subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 7,
      fName: 'Monkey',
      lName: 'Luffy',
      status: 'Unblock',
      unblockDate: '12/11/23-12/12/23',
      famCount: 5,
      subLink: '7subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 8,
      fName: 'Erza',
      lName: 'Scarlet',
      status: 'Unblock',
      unblockDate: '12/11/23-12/12/23',
      famCount: 9,
      subLink: '8subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 9,
      fName: 'Sky',
      lName: 'Forger',
      status: 'Unblock',
      unblockDate: '12/8/23-12/22/23',
      famCount: 15,
      subLink: '9subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 10,
      fName: 'Nobara',
      lName: 'Kugisaki',
      status: 'Unblock',
      unblockDate: '12/11/23-12/12/23',
      famCount: 6,
      subLink: '10subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 11,
      fName: 'Roy',
      lName: 'Mustang',
      status: 'Block',
      unblockDate: '12/11/23-12/12/23',
      famCount: 2,
      subLink: '11subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
    {
      key: 12,
      fName: 'Itachi',
      lName: 'Uchiha',
      status: 'Block',
      unblockDate: '12/11/23-12/12/23',
      famCount: 7,
      subLink: '12subLink',
      action: true,
      id: '0015400000xqRF5AAM',
    },
  ]);

  return (
    <>
      <DataTableComponent
        pageTitle="Manage Clients"
        buttonTitle="Add New Client"
        iconBtn="plus"
        tableData={items}
        tableTitles={[
          { key: 'lName', title: 'Last Name', sortable: true },

          {
            key: 'fName',
            title: 'First Name',
            sortable: true,
            defaultSort: true,
          },
          { key: 'status', title: 'Status', sortable: true },
          { key: 'unblockDate', title: 'Unblock Date', sortable: true },
          { key: 'famCount', title: 'Family Members', sortable: true },
          { key: 'subLink', title: 'Subscription Link' },
        ]}
        cellRenderer={(value: any) => {
          return <Text style={styles.subText}>{value}</Text>;
        }}
        actionChildrenTitle="Actions"
        actionChildren={
          <Button
            mode="contained"
            style={{
              backgroundColor: '#FFFFFF',
              borderColor: '#ED662C',
              borderRadius: 120,
              borderWidth: 1,
              height: 40,
            }}
            rippleColor="#ED662C">
            <Link
              to='/view-report'
              style={{ color: '#ED662C', textDecoration: 'none' }}>
              Manage
            </Link>
          </Button>
        }
      />
    </>
  );
}

const styles = StyleSheet.create({
  subText: {
    color: '#2B3674',
    fontSize: 15,
    fontWeight: '700',
  },
});
