import { Box } from '@react-native-material/core';
import React from 'react';
import { View } from 'react-native';

import { main } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';

import FamilyProfile from '../components/FamilyProfile';

export default function ManageFamily() {
  return (
    <PageContainer isClient={true}>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={main.centeredContainer}>
          <FamilyProfile />
        </View>
      </Box>
    </PageContainer>
  );
}
