/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { View, Linking, StyleSheet } from 'react-native';
import { Text, Icon } from 'react-native-paper';

import { ListsProps } from '@type/dashboard.type';

export default function Lists({ list }: ListsProps) {
  return (
    <View>
      {list.map((item, index) => (
        <Text key={index} numberOfLines={1}>
          {item.startsWith('http') ? (
            <View style={styles.mainView}>
              <Icon source="star-four-points" size={18} color="#ED662C" />
              <Text
                style={styles.listText}
                onPress={() => Linking.openURL(item)}>
                {item}
              </Text>
            </View>
          ) : (
            <View style={styles.mainView}>
              <Icon source="star-four-points" size={18} color="#ED662C" />
              <Text style={styles.listLink} numberOfLines={1}>
                {item}
              </Text>
            </View>
          )}
        </Text>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  listLink: {
    color: '#A3AED0',
    marginLeft: 5,
  },
  listText: {
    color: '#0000EE',
    marginLeft: 5,
    textDecorationLine: 'underline',
  },
  mainView: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 13,
    paddingTop: 13,
  },
});
