/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import { View } from 'react-native';
import { Modal, Portal, Button, SegmentedButtons } from 'react-native-paper';

import { Bureau } from '@type/creditBureau.type';

import { creditActions } from '../utils/credit.util';

import DatePicker from './DatePicker';

export default function ReblockModal({ item }: { item: Bureau }) {
  const buttons = Object.entries(item.Actions).map(([action, pathUrl]) => ({
    value: pathUrl,
    icon: creditActions[action as keyof typeof creditActions].icon,
  }));

  const [valueSelected, setValueSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const cancelModalDate = () => {
    setValueSelected(false);
    setOpen(false);
  };

  const submitModalDate = () => {
    if (valueSelected) {
      setOpen(false);
    }
  };

  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
    setValueSelected(true);
  };

  return (
    <>
      <SegmentedButtons
        value={'block'}
        buttons={buttons}
        onValueChange={action => {
          console.log('action', action);
          if (action.includes('unblock')) {
            setOpen(true);
          }
        }}
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          alignItems: 'center',
        }}
      />
      <Portal>
        <Modal
          visible={open}
          onDismiss={submitModalDate}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <DatePicker
            selectedDate={selectedDate}
            onDateChange={handleDateChange}
          />
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              flexDirection: 'row',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              justifyContent: 'center',
            }}>
            <Button
              onPress={submitModalDate}
              mode="contained"
              style={{ margin: 10 }}>
              Submit
            </Button>
            <Button
              onPress={cancelModalDate}
              mode="contained"
              theme={{ colors: { primary: 'rgb(129, 43, 43)' } }}
              style={{ margin: 10 }}>
              Cancel
            </Button>
          </View>
        </Modal>
      </Portal>
    </>
  );
}
