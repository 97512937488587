/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { Link } from 'react-router-dom';

import { KpisProps } from '@type/dashboard.type';

export default function Kpis({
  metric,
  subtext,
  coloredSubtext,
  link,
  buttonText,
}: KpisProps) {
  return (
    <View>
      <View style={{ marginBottom: 26 }}>
        <Text variant="headlineMedium" style={styles.textTitle}>
          {metric}
        </Text>
      </View>
      {subtext ? (
        <View style={styles.subtextView}>
          <Text variant="labelLarge" style={styles.textSubtext}>
            {subtext}
          </Text>
          <Text style={styles.textColoredSubtext}>{coloredSubtext}</Text>
        </View>
      ) : (
        <></>
      )}
      {buttonText ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            top: '70%',
          }}>
          <Button
            mode="contained"
            style={{
              backgroundColor: 'transparent',
              backgroundImage:
                'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
            }}>
            <Link to={link} style={{ color: 'white', textDecoration: 'none' }}>
              {buttonText}
            </Link>
          </Button>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  subtextView: {
    display: 'flex',
    flexDirection: 'row',
  },
  textColoredSubtext: {
    color: '#05CD99',
    fontWeight: 500,
    padding: 0,
  },
  textSubtext: {
    color: '#A3AED0',
    fontWeight: 500,
    padding: 0,
  },
  textTitle: {
    color: '#2B3674',
    fontWeight: 700,
    padding: 0,
  },
});
