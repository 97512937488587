import {
  Flex,
  Box,
  Surface,
  TextInput,
  Text,
  Button,
} from '@react-native-material/core';
import React, { useState } from 'react';
import {
  Image,
  StyleSheet,
  Dimensions,
  ImageBackground,
  LayoutChangeEvent,
  View,
} from 'react-native';

import TopNav from '@shared/components/layout/TopNav';

export default function Login() {
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;

  const [authNavHeight, setAuthNavHeight] = useState(0);
  const [authNavWidth, setAuthNavWidth] = useState(0);
  const [isCode, setIsCode] = useState(false);

  const handleAuthNavLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    const { width } = event.nativeEvent.layout;
    setAuthNavHeight(height);
    setAuthNavWidth(width);
  };

  const contentHeight = screenHeight - authNavHeight;

  const handleInputCode = () => {
    setIsCode(true);
  };

  const handleInputCodeReset = () => {
    setIsCode(false);
  };

  return (
    <Flex>
      <View onLayout={handleAuthNavLayout}>
        <TopNav isClient={false} />
      </View>
      <ImageBackground
        source={{
          uri: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
        }}
        style={{
          height: contentHeight,
          width: screenWidth,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Surface
          elevation={12}
          category="medium"
          style={[
            styles.childContainer,
            screenWidth > 700 && styles.centeredsmDesktop,
            screenWidth > 1201 && styles.centeredlgDesktop,
          ]}>
          {isCode ? (
            <>
              <Box style={[styles.marginSpacing, { alignItems: 'center' }]}>
                <Image
                  source={require('../../../assets/images/logoBlue.png')}
                  style={{ width: 150, height: 100 }}
                />
                <Text variant="h4" style={{ color: '#7F8184' }}>
                  Forgot Password?
                </Text>
              </Box>
              <Box style={styles.marginSpacing}>
                <TextInput label="Email" variant="outlined" />
              </Box>
              <Box style={styles.marginSpacing}>
                <Button
                  title="Send Code"
                  color="#677C8C"
                  tintColor="white"
                  onPress={handleInputCodeReset}
                />
              </Box>
            </>
          ) : (
            <>
              <Box style={[styles.marginSpacing, { alignItems: 'center' }]}>
                <Image
                  source={require('../../../assets/images/logoBlue.png')}
                  style={{ width: 150, height: 100 }}
                />
                <Text variant="h4" style={{ color: '#7F8184' }}>
                  Verification
                </Text>
              </Box>
              <Box style={styles.marginSpacing}>
                <TextInput label="Enter your 6 digit code" variant="outlined" />
              </Box>
              <Box style={styles.marginSpacing}>
                <Button
                  title="Verification"
                  color="#677C8C"
                  tintColor="white"
                  onPress={handleInputCode}
                />
              </Box>
            </>
          )}
        </Surface>
      </ImageBackground>
    </Flex>
  );
}

const styles = StyleSheet.create({
  centeredlgDesktop: {
    alignItems: 'stretch',
    justifyContent: 'center',
    width: 1000,
  },
  centeredsmDesktop: {
    alignItems: 'stretch',
    justifyContent: 'center',
    width: 720,
  },
  childContainer: {
    padding: 20,
    width: 300,
  },

  marginSpacing: {
    margin: 10,
  },
});
