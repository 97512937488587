import {
  KC_ISSUER,
  KC_CLIENT_ID,
  KC_REALM,
  KC_REDIRECT_URL_IOS,
  KC_REDIRECT_URL_WEB,
  API_ENDPOINT,
} from '@env';

export const config = {
  kc: {
    issuer: KC_ISSUER,
    clientId: KC_CLIENT_ID,
    realm: KC_REALM,
    redirectUrl: {
      ios: KC_REDIRECT_URL_IOS,
      web: KC_REDIRECT_URL_WEB,
    },
  },
  api: {
    endpoint: API_ENDPOINT,
  },
};
