/* eslint-disable react-native/no-inline-styles */

import { Box } from '@react-native-material/core';
import React from 'react';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import { main } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import { CreditAPIResponse } from '@type/creditBureau.type';

import BureausTable from '../components/BureausTable';
import { getClientCreditBureaus } from '../creditBureauService';

export default function ManageCredit() {
  const [loading, setLoading] = useState(true);
  const [clientID, _setClientID] = useState('0015400000xqRF5AAM');
  const [requestData, setRequestData] = useState<
    CreditAPIResponse | null | undefined
  >();

  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      const token = auth.user?.access_token || '';
      const response = await getClientCreditBureaus(clientID, token);
      if (response !== undefined) {
        setRequestData(response || null);
        setLoading(false);
      } else {
        setRequestData(null);
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID]);

  return (
    <PageContainer isClient={true}>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={main.centeredContainer}>
          {loading ? (
            <ActivityIndicator
              animating={true}
              size="large"
              style={{ marginTop: 20 }}
            />
          ) : (
            <BureausTable data={requestData} />
          )}
        </View>
      </Box>
    </PageContainer>
  );
}
