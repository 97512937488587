import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';

import { main } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import { CreditLock } from '@type/creditLock.type';

import LockUnlockTable from '../components/LockUnlockTable';
import { useCreditLock } from '../creditLockHook';

export default function LockMyCredit() {
  const { state, getCreditLock } = useCreditLock();

  const [creditLockData, setCreditLockData] = useState({} as CreditLock);
  const [_loading, setLoading] = useState(true);
  const [_error, setError] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getCreditLock();
        if (data) setCreditLockData(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  return (
    <PageContainer isClient={true}>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={main.centeredContainer}>
          <Text>State: {JSON.stringify(state)} </Text>
          <Text>{creditLockData.name}</Text>
          <LockUnlockTable />
        </View>
      </Box>
    </PageContainer>
  );
}
