import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { ProfileUser } from '@type/profile.type';

export const profileService = {
  async getProfile(token: string): Promise<ProfileUser> {
    const payload: APIPayload = {
      method: 'GET',
      path: 'core/v1/client/0015400000xqRF5AAM/profile',
    };
    const res = (await requestAPI(payload, token)) as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching partner contact.');
    const profile = res.data.data as ProfileUser;
    return profile;
  },
};