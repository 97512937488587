/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { Surface } from '@react-native-material/core';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';

import { FormsProps } from '@type/form.type';

export default function Forms({
  title,
  children,
  withEdit,
  handleToggleEditMode = () => {},
  layerIndex,
}: FormsProps) {
  return (
    <Surface
      elevation={1}
      style={[styles.parentContainer, { zIndex: layerIndex }]}>
      <View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Text variant="titleLarge" style={styles.mainText}>
            {title}
          </Text>
          {withEdit ? (
            <Button
              mode="contained"
              style={styles.buttonSave}
              icon="file-edit-outline"
              textColor="white"
              onPress={() => handleToggleEditMode()}>
              Edit Mode
            </Button>
          ) : (
            <></>
          )}
        </View>
        {children}
      </View>
    </Surface>
  );
}

const styles = StyleSheet.create({
  buttonSave: {
    backgroundColor: '#ED662C',
    borderColor: '#FF1818',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
  },
  mainText: {
    color: '#2B3674',
    fontWeight: '700',
    marginBottom: 20,
  },
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    marginTop: 10,
    padding: 20,
  },
});
