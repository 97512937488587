import React, { createContext, useReducer } from 'react';

import {
  CreditLock,
  CreditLockState,
  CreditLockContextProps,
  CreditLockProviderProps,
} from '@type/creditLock.type';

import { creditLockService } from './creditLockService';

const initialState: CreditLockState = {
  creditLock: { name: '' },
  loading: false,
  error: null,
};

type Action =
  | { type: 'CREDITLOCK_GET' }
  | { type: 'CREDITLOCK_GET_SUCCESS'; payload: CreditLock }
  | { type: 'CREDITLOCK_GET_ERROR'; payload: string }
  | { type: 'CREDITLOCK_UPDATE' }
  | { type: 'CREDITLOCK_UPDATE_SUCCESS'; payload: CreditLock }
  | { type: 'CREDITLOCK_UPDATE_ERROR'; payload: string };

const creditLockReducer = (
  state: CreditLockState,
  action: Action,
): CreditLockState => {
  switch (action.type) {
    case 'CREDITLOCK_GET':
      return { ...state, loading: true };
    case 'CREDITLOCK_GET_SUCCESS':
      return { ...state, loading: false, creditLock: action.payload };
    case 'CREDITLOCK_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const CreditLockContext = createContext<
  CreditLockContextProps | undefined
>(undefined);

export const CreditLockProvider: React.FC<CreditLockProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(creditLockReducer, initialState);

  const getCreditLock = async () => {
    dispatch({ type: 'CREDITLOCK_GET' });
    try {
      const creditLock = await creditLockService.getCreditLock();
      dispatch({ type: 'CREDITLOCK_GET_SUCCESS', payload: creditLock });
      return creditLock;
    } catch (error) {
      dispatch({
        type: 'CREDITLOCK_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const updateCreditLock = async (updates: Partial<CreditLock>) => {
    dispatch({ type: 'CREDITLOCK_UPDATE' });
    try {
      const updatedCreditLock =
        await creditLockService.updateCreditLock(updates);
      dispatch({
        type: 'CREDITLOCK_UPDATE_SUCCESS',
        payload: updatedCreditLock,
      });
      return updatedCreditLock;
    } catch (error) {
      dispatch({
        type: 'CREDITLOCK_UPDATE_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <CreditLockContext.Provider
      value={{ state, getCreditLock, updateCreditLock }}>
      {children}
    </CreditLockContext.Provider>
  );
};
