/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Button } from 'react-native-paper';
import { Link } from 'react-router-dom';

import { CallToActionProps } from '@type/dashboard.type';

export default function CallToAction({
  link,
  buttonText,
  headerText,
  subText,
}: CallToActionProps) {
  return (
    <View>
      <Text variant="headlineMedium" style={styles.textHeadline}>
        {headerText}
      </Text>
      <Text variant="labelLarge" style={styles.textSubtext}>
        {subText}
      </Text>
      <View style={styles.buttonView}>
        <Button mode="contained" buttonColor="white" style={{ margin: 7 }}>
          <Link to={link} style={styles.linkStyle}>
            {buttonText}
          </Link>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonView: {
    display: 'flex',
    flexDirection: 'row',
  },
  linkStyle: {
    color: 'black',
    textDecoration: 'none',
  },
  textHeadline: {
    color: 'white',
    fontWeight: 700,
    margin: 7,
  },
  textSubtext: {
    color: 'white',
    fontWeight: 500,
    margin: 7,
  },
});
