import { Client, UpdatedClient } from '@type/client.type';

export const clientService = {
  async getClient(): Promise<Client> {
    return new Promise(resolve => {
      setTimeout(() => {
        const client = {
          name: 'John Doe',
        };
        resolve(client);
      }, 3000);
    });
  },
  async updateClient(updates: Partial<Client>): Promise<UpdatedClient> {
    const updatedClient = {
      name: 'John Doe',
      ...updates,
    };
    return updatedClient;
  },
};
