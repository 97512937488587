/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { DataTable } from 'react-native-paper';

import { Bureau } from '@type/creditBureau.type';

import ReblockModal from './ReblockModal';

export default function BureauTableRow({
  item,
}: {
  item: Bureau;
  onActionBtn: (action: string) => void;
}) {
  return (
    <DataTable.Row testID="BureauRow" key={item.Bureau}>
      <DataTable.Cell testID="Bureau" style={{ flex: 1 }}>
        {item.Bureau}
      </DataTable.Cell>
      <DataTable.Cell style={{ flex: 1 }}>{item.Type}</DataTable.Cell>
      <DataTable.Cell style={{ flex: 1 }}>{item.Stage}</DataTable.Cell>
      <DataTable.Cell style={{ flex: 1 }}>{item.Score}</DataTable.Cell>
      <DataTable.Cell
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
        }}>
        {Object.keys(item.Actions).length > 0 && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              justifyContent: 'center',
            }}>
            <ReblockModal item={item} />
          </div>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );
}
