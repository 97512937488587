/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';

import { ProfileProps } from '@type/dashboard.type';

export default function Profile({
  profileName,
  personEmail,
  companyEmail,
  phoneNumber,
}: ProfileProps) {
  return (
    <View>
      <Text variant="headlineMedium" style={styles.textHeader}>
        {profileName}
      </Text>
      <Text style={styles.textSubtext}>{personEmail}</Text>
      <Text style={styles.textSubtext}>{companyEmail}</Text>
      <Text style={styles.textSubtext}>{phoneNumber}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  textHeader: {
    color: '#2B3674',
    fontWeight: 700,
    marginTop: 18,
    padding: 0,
  },
  textSubtext: {
    color: '#A3AED0',
    paddingBottom: 4,
    paddingTop: 4,
  },
});
