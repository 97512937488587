/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { Linking, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Button, Card, Text } from 'react-native-paper';

import { RegistrationLinkComponent } from '@type/registrationLink.type';

export default function RegistrationButton({
  title,
  subText,
  buttonLink,
}: RegistrationLinkComponent) {
  const containerStyle = {
    width: '33.33%',
    padding: 10,
  };

  return (
    <View style={containerStyle as StyleProp<ViewStyle>}>
      <Card style={styles.mainCard} mode="elevated" elevation={1}>
        <Card.Content>
          <Text variant="titleMedium" style={styles.textTitle}>
            {title}
          </Text>
        </Card.Content>
        <Card.Content
          style={{ alignItems: 'flex-start', marginBottom: 10, marginTop: 10 }}>
          <Text variant="labelLarge" style={styles.textSubtext}>
            {subText}
          </Text>
        </Card.Content>
        <Card.Content style={{ alignItems: 'flex-end' }}>
          <Button
            mode="contained"
            style={{
              backgroundColor: 'transparent',
              backgroundImage:
                'linear-gradient(156.45deg, #50768E 5.99%, #2C4758 84.82%)',
            }}
            onPress={() => Linking.openURL(buttonLink)}>
            Register
          </Button>
        </Card.Content>
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  mainCard: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    padding: 10,
  },
  textSubtext: {
    fontWeight: '500',
  },
  textTitle: {
    color: '#2B3674',
    fontWeight: '700',
    padding: 0,
  },
});
