import axios from 'axios';

import { config } from '@configs/app.config';
import { APIPayload } from '@type/api.type';

const { endpoint } = config.api;

export async function requestAPI(apiPayload: APIPayload, token: string) {
  try {
    const apiEndpoint = `${endpoint}${apiPayload.path}`;
    const authToken = token;

    const headers = {
      authorizationToken: authToken,
    };

    const apiRes = await axios({
      method: apiPayload.method,
      url: apiEndpoint,
      params: apiPayload.params,
      headers: headers,
      data: apiPayload.body,
    });
    return apiRes;
  } catch (error) {
    console.error(`Error in requestAPI: ${error}`);
    return {};
  }
}
