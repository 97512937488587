/* eslint-disable react-native/no-color-literals */
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { Link, useLocation } from 'react-router-dom';

import logo from '@assets/images/logoRegal.png';
import PromoCard from '@shared/components/common/PromoCard';

interface NavigationProps {
  isClient: boolean;
  setNavOpen: (str: boolean) => void;
  navOpen: boolean;
  handleNav: (str: boolean) => void;
}

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(12)} + 1px)`, //space between the end of the navigation and the icon on closed nav
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MenuNavigation({
  isClient,
  setNavOpen,
  navOpen,
  handleNav,
}: NavigationProps) {
  const theme = useTheme();

  const { pathname, state } = useLocation();

  const [open, setOpen] = useState(navOpen);
  const navMenus = isClient ? menus.client : menus.partner;

  const boxWithStyle = !open
    ? {
        width: 90,
      }
    : { width: drawerWidth };

  const showActiveDividerStyle = (pathTo: string) => {
    if (pathname === pathTo && open) {
      return {
        borderRight: '4px solid #ED662C',
      };
    }
  };

  const auth = useAuth();
  const handleLogout = () => {
    auth.signoutSilent();
  };

  useEffect(() => {
    setOpen(navOpen);
  }, [navOpen]);

  useEffect(() => {
    const navState = state;
    if (navState !== null) {
      setOpen(state);
      setNavOpen(state);
    }
  }, []); // run onLoad

  return (
    <Box
      testID="box-menu"
      sx={{
        ...boxWithStyle,
        display: 'flex',
      }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        open={open}
        style={{
          backgroundColor: '#fff',
        }}>
        <View
          style={{
            ...styles.imageContainerCollapsed,
            display: navOpen ? 'none' : 'flex',
          }}
          id="container-header-collapsed">
          <Image source={logo} style={styles.imageCollapsed} />
        </View>
        <Divider />
      </AppBar>
      <Drawer
        data-testid="drawer-navigation"
        id="drawer-navigation"
        variant="permanent"
        open={open}
        elevation={0}
        style={{
          display: 'flex',
          flex: 1,
        }}>
        <DrawerHeader>
          <View
            style={{
              ...styles.imageContainer,
              display: navOpen ? 'flex' : 'none',
            }}
            id="container-header-open">
            <Image source={logo} style={styles.image} />
          </View>
        </DrawerHeader>
        <Divider />
        <List
          style={{
            marginTop: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
          {navMenus.map((item, index) => (
            <ListItem
              disablePadding={open}
              key={item.title}
              role="listitem"
              sx={{
                display: 'block',
              }}
              onClick={() => {
                if (item.title === 'Logout') {
                  handleLogout();
                }
              }}>
              <Link
                to={item.linkTo}
                state={navOpen}
                style={{
                  display: 'block',
                  height: 45,
                  ...showActiveDividerStyle(item.linkTo),
                }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Icon
                      source={item.icon}
                      color={pathname === item.linkTo ? '#ED662C' : '#A3AED0'}
                      size={20}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      opacity: open ? 1 : 0,
                      fontSize: 13,
                      ...(pathname === item.linkTo
                        ? styles.labelActive
                        : styles.labelText),
                    }}
                    hidden={!open}>
                    {item.title}
                  </Typography>
                </ListItemButton>
                {/* menu text for the collapsed navigation */}
                <View style={{ marginTop: '-10px' }}>
                  <Typography
                    sx={{
                      fontSize: 8,
                      textAlign: 'center',
                      ...(pathname === item.linkTo
                        ? styles.labelActive
                        : styles.labelText),
                    }}
                    hidden={open}>
                    {item.title}
                  </Typography>
                </View>
              </Link>
            </ListItem>
          ))}
        </List>
        <Box
          id="promo-content"
          hidden={navOpen}
          sx={{
            flex: 1,
            height: 50,
            width: 179,
            display: 'flex',
            justfifyContent: 'flex-end',
            alignSelf: 'flex-end',
            position: 'relative',
            overflow: 'hidden',
          }}>
          <PromoCard
            addtlStyle={{
              alignSelf: 'flex-end',
              position: 'absolute',
              display: navOpen ? 'inline-block' : 'none',
            }}>
            <>
              <View
                style={{
                  alignSelf: 'baseline',
                  justifyContent: 'space-around',
                }}>
                <Text
                  variant="titleSmall"
                  style={{
                    ...styles.promoText,
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}>
                  Promotional Content
                </Text>
                <Text
                  variant="bodySmall"
                  style={{
                    ...styles.promoText,
                    fontSize: 11,
                  }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Text>
              </View>
            </>
          </PromoCard>
        </Box>
      </Drawer>
    </Box>
  );
}

const styles = StyleSheet.create({
  image: {
    display: 'flex',
    height: '50%',
    justifyContent: 'center',
    margin: 'auto',
    width: 100,
  },
  imageCollapsed: {
    display: 'flex',
    height: 30,
    margin: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 60,
  },
  imageContainer: {
    display: 'flex',
    height: 110,
    width: 180,
  },
  imageContainerCollapsed: {
    display: 'flex',
    height: 80,
    paddingLeft: 10,
    paddingTop: 10,
    width: 70,
  },
  labelActive: {
    color: '#000',
    fontWeight: 'bold',
  },
  labelText: {
    color: '#A3AED0',
    fontWeight: 'bold',
  },
  promoText: {
    color: '#fff',
    marginTop: 3,
    textAlign: 'center',
  },
});

const menus = {
  partner: [
    {
      title: 'Dashboard',
      linkTo: '/dashboard',
      icon: 'view-dashboard',
    },
    {
      title: 'Clients',
      linkTo: '/clients',
      icon: 'account-multiple',
    },
    {
      title: 'Accounts',
      linkTo: '/',
      icon: 'account',
    },
    {
      title: 'Settings',
      linkTo: '/settings',
      icon: 'cog',
    },
    {
      title: 'Help',
      linkTo: '/',
      icon: 'sticker-text',
    },
    {
      title: 'Logout',
      linkTo: '/',
      icon: 'logout',
    },
  ],
  client: [
    {
      title: 'View Reports',
      linkTo: '/view-report',
      icon: 'chart-box',
    },
    {
      title: 'Manage Family',
      linkTo: '/manage-family',
      icon: 'family-tree',
    },
    {
      title: 'My Profile',
      linkTo: '/my-profile',
      icon: 'account-cog',
    },
    {
      title: 'Manage Credit',
      linkTo: '/manage-credit',
      icon: 'bank',
    },
    {
      title: 'Logout of Client',
      linkTo: '/dashboard',
      icon: 'logout',
    },
  ],
};
